import { Image, Alert, AlertIcon, Box, Button, Flex, FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Stack, Icon, AlertDescription, Text } from '@chakra-ui/react';
import { FiAtSign, FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import imgUrl from '../assets/logo.png';
import { FormEvent, useEffect, useState } from 'react';
import { useAuth } from '../lib/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { user, login, logout } = useAuth();
  const navigate = useNavigate();

  async function onFormSubmit(e: FormEvent) {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      logout();
      await login(email, password);
      navigate('/');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.status === 400) {
          setError('Usuário ou senha incorretos');
        } else {
          setError('Falha ao conectar no servidor');
        }
      }
      console.error(error);
      // setError('Falha ao conectar no servidor');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user != null) navigate('/');
  }, [user])

  return (
    <>
      <Flex
        flexDirection='column'
        width='100wh'
        height='100vh'
        backgroundColor='gray.50'
        justifyContent='center'
        alignItems='center'
      >
        <Stack
          flexDir='column'
          mb='2'
          justifyContent='center'
          alignItems='center'
        >
          <Box maxW={'600px'}>
            <form onSubmit={(e) => onFormSubmit(e)}>
              <Stack
                spacing={4}
                p='1rem'
                backgroundColor='whiteAlpha.900'
                boxShadow='md'
              >
                <Flex width={'100%'} justifyContent={'center'} marginY={'15px'}>
                  <Image
                    alt='Logo Segurvia'
                    src={imgUrl} height={'35px'} mb={'-7px'}
                    filter={'invert(1)'} />
                </Flex>
                <FormControl isRequired>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <Icon as={FiAtSign} />
                    </InputLeftElement>
                    <Input
                      type='email'
                      placeholder='Endereço de E-mail'
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl isRequired>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <Icon as={FiLock} />
                    </InputLeftElement>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Senha'
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                    <InputRightElement width='5rem'>
                      <Button size='md' ml={'40px'} backgroundColor={'transparent'}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword
                          ? <Icon as={FiEyeOff} />
                          : <Icon as={FiEye} />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {error !== '' && (
                  <Alert status='error'>
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                <Button
                  borderRadius={0}
                  type='submit'
                  variant='solid'
                  rounded='md'
                  shadow='md'
                  colorScheme='blue'
                  isLoading={isLoading}
                >
                  Entrar
                </Button>
                {/* @ts-ignore */}
                {__GIT_HASH__ && __GIT_BRANCH__ && 
                  // @ts-ignore
                  <Text m={-2} color={'gray.300'} textAlign={'center'}>segurvia@{__GIT_HASH__} ({__GIT_BRANCH__})</Text>}
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}