import axios from 'axios';

export const api = axios.create({
  baseURL: import.meta.env.DEV ? 'http://localhost:8000/api/' : '/api/',
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem('token') || '';
  if (import.meta.env.DEV)
    console.debug(`[REQUEST] ${config.method} - ${config.url}`, config.data);
  return config;
});

api.interceptors.response.use((res) => {
  if (import.meta.env.DEV)
    console.debug(`[RESPONSE] ${res.config.method} - ${res.config.url} - ${res.status}`, res.data);
  return res;
});