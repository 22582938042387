import { createBrowserRouter, RouterProvider, useNavigate, useRouteError } from 'react-router-dom';
import { RootPage, WorkersPage, ProjectsPage, LoginPage, ProjectView } from './pages';
import { AuthProvider } from './lib/auth';
import { useAuth } from './lib/hooks/useAuth';
import { Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { api } from './lib/api';

function ErrorBoundary() {
  const [loading, setLoading] = useState(false);

  const error = useRouteError();
  const { user } = useAuth();
  const navigate = useNavigate();

  async function reportBug() {
    setLoading(true);
    try {
      const data: any = {};

      if ('consoleHistory' in window) {
        data.logs = JSON.stringify(window.consoleHistory);
      }
      if (user) {
        data.user = user.id;
      }

      data.stacktrace = JSON.stringify((error as Error).stack);

      await api.post(`/misc/bugreport/`, data);
    } finally {
      setLoading(false);
      navigate('/');
    }
  }

  return (<Flex width={'100vw'} height={'100vh'} justifyContent={'center'} alignContent={'center'}>
    <HStack>
      <Text fontSize={'7xl'}>:(</Text>
      <VStack alignItems={'start'}>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Um erro fatal ocorreu</Text>
        <Button isLoading={loading} colorScheme='red' width={'100%'} onClick={reportBug} variant={'ghost'}>
          Reportar erro e voltar
        </Button>
      </VStack>
    </HStack>
  </Flex>);
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
      errorElement: <ErrorBoundary/>,
      children: [
        {
          path: 'workers',
          element: <WorkersPage />
        },
        {
          path: 'projects',
          element: <ProjectsPage />
        },
        {
          path: 'project/:id',
          element: <ProjectView />
        }
      ]
    },
    {
      path: "/login",
      element: <LoginPage />
    }
  ]);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;


