import { useState, ChangeEvent } from 'react';
import { Input, InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react';

interface CustomNumberInputProps {
  value: number,
  onChange: (n: number) => void,
  type?: 'simple' | 'money' | 'with-unit',
  unit?: string,
  autoFocus?: boolean;
}

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  useGrouping: false,
});

function formatNumber(n: number): string {
  return formatter.format(n);
}

function parseNumber(str: string): number {
  return parseFloat(str.replace(/\./g, '').replace(',', '.'));
}

export function CustomNumberInput(props: CustomNumberInputProps) {
  const [displayValue, setDisplayValue] = useState(formatNumber(props.value || 0));

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let userInput = e.target.value;
    userInput = userInput.replace(/[^0-9,]/g, ''); // Only digits and comma

    const [head, ...rest] = userInput.split(',');
    if (rest.length > 0)
      userInput = `${head},${rest.join('').substring(0, 2)}`

    userInput.replace('.', '');
    setDisplayValue(userInput);

    if (/^\d+([,]\d{0,2})?$/.test(userInput)) {
      const parsed = parseNumber(userInput);
      if (!isNaN(parsed)) {
        props.onChange(parsed);
      }
    }
  }

  function innerInput() {
    return (<Input type='text'
      value={displayValue} onChange={handleChange}
      pattern='[0-9]+([,][0-9]{1,2})?'
      onBlur={(e) => {
        const input = e.target.value;
        if (input.length == 0) {
          setDisplayValue("0,00");
          props.onChange(0);
        }
      }}
      autoFocus={props.autoFocus || false} />);
  }

  if (!props.type || props.type === 'simple') {
    return innerInput();
  }

  if (props.type && props.type === 'money') {
    return (
      <InputGroup>
        <InputLeftAddon>R$</InputLeftAddon>
        {innerInput()}
      </InputGroup>
    );
  }

  if (props.type && props.unit && props.type === 'with-unit') {
    return (
      <InputGroup>
        {innerInput()}
        <InputRightAddon>{props.unit}</InputRightAddon>
      </InputGroup>
    );
  }

  throw new Error('Invalid arguments');
};
