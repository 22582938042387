import { Alert, AlertDescription, AlertIcon, Button, FormControl, FormLabel, Input, ModalCloseButton, Text } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAuth } from '../../lib/hooks/useAuth';
import { api } from '../../lib/api';

export function ChangePasswordModal(
  props: {
    isOpen: boolean,
    onClose: () => void,
  }
) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  function isEverythingValid() {
    return password.length >= 6 && confirmPassword == password;
  }

  useEffect(() => {
    if (password == '') return;

    if (password.length < 6) {
      return setError('Senha deve conter 6 ou mais caracteres');
    }

    if (password != confirmPassword) {
      return setError('Senhas precisam ser iguais');
    }

    setError('');
  }, [password, confirmPassword]);

  async function changePassword() {
    try {
      setLoading(true);
      api.post(`/user/${user!.id}/passwd/`, {
        password: password,
        confirm_password: confirmPassword,
      });

      props.onClose();
    } catch (error) {
      setError('Falha ao alterar a senha. Tente novamente');
      console.error('Failted to change password', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar senha</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl isRequired>
            <FormLabel marginBottom={0}>Senha</FormLabel>
            <Input type='password' placeholder='Nova senha' autoFocus={true} autoComplete='off'
              onChange={(e) => setPassword(e.target.value)} />
          </FormControl>

          <FormControl isRequired mt={5}>
            <FormLabel marginBottom={0}>Confirmar senha</FormLabel>
            <Input type='password' placeholder='Confirmar senha' autoComplete='off'
              onChange={(e) => setConfirmPassword(e.target.value)} />
          </FormControl>

          {error &&
            <Alert status='error' mt={5} borderRadius={5}>
              <AlertIcon />
              <AlertDescription><Text>{error}</Text></AlertDescription>
            </Alert>
          }
        </ModalBody>

        <ModalFooter>
          <Button isLoading={loading} colorScheme='blue' disabled={!isEverythingValid()}
            onClick={changePassword}
          >
            Alterar senha
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}