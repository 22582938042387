import { Flex, Box, Text, Th, Td, TableContainer, Table, Thead, Tr, Tbody, Spinner, HStack, Button, InputGroup, Icon, Input, InputLeftElement, useDisclosure, VStack, Divider } from '@chakra-ui/react';
import { Project } from '../models/Project';
import { useState, useEffect } from 'react';
import { api } from '../lib/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../lib/hooks/useAuth';
import { FiCheckCircle, FiPlus, FiSearch } from 'react-icons/fi';
import { formatDate, getHighlightedText } from '../lib/util';
import { ProjectModal, ProjectModalMode } from './ProjectModal';
import { useIsMobile } from '../lib/mobileHook';

export function ProjectsPage() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [filtered, setFiltered] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');

  const [modalMode, setModalMode] = useState<ProjectModalMode>({ type: 'create', project: null });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMobile } = useIsMobile();
  const { user } = useAuth();

  const fetchProjects = async () => {
    try {
      const response = await api.get<Project[]>('/project/');
      setProjects(response.data.map(p => {
        return {
          ...p,
          start_date: new Date(p.start_date),
          end_date: new Date(p.end_date)
        };
      }));
    } catch {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    setFiltered(projects.filter(p => {
      if (filter.length === 0) return true;

      if (p.addresses) {
        for (const a of p.addresses) {
          const data = `${a.logradouro} ${a.numero} ${a.cidade} ${a.uf} ${a.cep}`;
          if (data.includes(filter)) return true;
        }
      }

      const data = `${p.name} ${formatDate(p.start_date, false)} ${formatDate(p.end_date, false)}`;
      return data.includes(filter);
    }));
  }, [filter, projects]);

  const navigate = useNavigate();

  function SingleProject(props: { i: number, allProjects: Project[], self: Project, filterThings: { filter: string, regExp: RegExp; }; }) {
    const self = props.self;
    const id = props.self.id;

    const startDate = new Date(props.self.start_date)
      .toLocaleDateString('pt-BR');

    const endDate = new Date(props.self.end_date)
      .toLocaleDateString('pt-BR');

    const { filter, regExp } = props.filterThings;
    const { isMobile } = useIsMobile();

    const click = () => navigate(`/project/${id}`);
    const dname = filter ? getHighlightedText(self.name, filter, regExp) : self.name;
    const dstartDate = filter ? getHighlightedText(String(startDate), filter, regExp) : startDate;
    const dendDate = filter ? getHighlightedText(String(endDate), filter, regExp) : endDate;

    const daddresses = <VStack alignItems={'start'}>
      {self.addresses?.filter(a => a.enabled).map((a, i, arr) => {
        const asStr = `${a.logradouro}, ${a.numero}, ${a.cidade} - ${a.uf}`;
        return (
          <>
          <Text key={i} whiteSpace={'normal'}
            wordBreak={'break-word'}
          >{filter ? getHighlightedText(asStr, filter, regExp) : asStr}</Text>
          {i < (arr.length - 1) && <Divider />}
          </>
        );
      })}
    </VStack>;

    if (!isMobile())
      return (
        <Tr onClick={click} cursor={'pointer'}>
          <Td>{dname}</Td>
          <Td>{dstartDate}</Td>
          <Td>{dendDate}</Td>
          <Td>{daddresses}</Td>
          <Td>
            {self.completed ? <FiCheckCircle size={18} /> : <></>}
          </Td>
        </Tr>
      );
    else {
      const i = props.i;
      return (
        <>
          <Tr key={i}
            width={'100px'}
            backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
            cursor={'pointer'} className='tr-list' onClick={click}
          >
            <Th width={'100px'}>Nome</Th>
            <Td>{dname}</Td>
          </Tr>
          <Tr key={i + 1}
            backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
            cursor={'pointer'} className='tr-list' onClick={click}
          >
            <Th>Data de Início</Th>
            <Td>{dstartDate}</Td>
          </Tr>
          <Tr key={i + 2}
            backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
            cursor={'pointer'} className={'tr-list'} onClick={click}
          >
            <Th>Data de Termíno</Th>
            <Td>{dendDate}</Td>
          </Tr>
          <Tr key={i + 3}          
            backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
            cursor={'pointer'} className={i === (props.allProjects.length - 1) ? '' : 'tr-separator'} onClick={click}
          >
            <Th>Endereços</Th>
            <Td>{daddresses}</Td>
          </Tr>
        </>
      );
    }
  }

  return (
    <Box
      p={3}
      bg='gray.50'
      flex={1}
      borderBottomRightRadius={10}
    >
      <ProjectModal
        isOpen={isOpen}
        onClose={onClose}
        mode={modalMode}
        onProjectAdd={(project: Project) => {
          setProjects([...projects, project]);
        }}
        onProjectDelete={(_project: Project) => { }}
        onProjectEdit={(_project: Project) => { }}
      />

      <Text fontSize='3xl' fontWeight={900}>
        Projetos
      </Text>
      {loading ? <Flex width={'100%'} justifyContent={'center'}><Spinner /></Flex> :
        <>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents={'none'}>
              <Icon as={FiSearch}></Icon>
            </InputLeftElement>
            <Input placeholder='Pesquisar projetos' onChange={(e) => {
              setFilter(e.target.value);
            }}></Input>
          </InputGroup>
          <Box height={'400px'} overflowY={'auto'}>
            {projects.length == 0 &&
              <Flex width={'100%'} justifyContent={'center'}>
                <Text fontWeight={'bold'} fontSize={'20px'}>Nenhum projeto cadastrado</Text>
              </Flex>}
            {projects.length > 0 && filtered.length === 0 && !loading &&
              <Flex width={'100%'} justifyContent={'center'}>
                <Text fontWeight={'bold'} fontSize={'20px'}>Nenhum projeto corresponde ao filtro</Text>
              </Flex>}
            <TableContainer>
              {filtered.length > 0 && filtered.length > 0 &&
                <Table variant={isMobile() ? 'simple' : 'striped'} colorScheme='gray'>
                  <Thead position={'sticky'} top={0} zIndex={1}>
                    {projects.length > 0 && !isMobile() &&
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Data de início</Th>
                        <Th>Data de término</Th>
                        <Th>Endereço(s)</Th>
                      </Tr>
                    }
                  </Thead>
                  <Tbody>
                    {filtered
                      .sort((a, b) => Number(a.completed) - Number(b.completed))
                      .map((p, i) => {
                        const filterRegExp = new RegExp(`(${filter})`, 'gi');
                        return <SingleProject key={i} i={i} allProjects={filtered} self={p} filterThings={{ filter: filter, regExp: filterRegExp }} />;
                      })}
                  </Tbody>
                </Table>
              }
            </TableContainer>
          </Box>
        </>
      }
      {user?.user_kind == 'admin' &&
        <HStack mt={4} justifyContent={'end'}>
          <Button
            leftIcon={<FiPlus />}
            colorScheme='blue'
            onClick={() => {
              setModalMode({ type: 'create', project: null });
              onOpen();
            }}>Novo projeto</Button>
        </HStack>}
    </Box>
  );
};
