import { api } from '../api';
import axios from 'axios';
import { User } from '../../models/User';

export interface PartialUserModel {
  name: string, email: string, document: string, password: string, kind: string,
}

export async function fetchWorkers(): Promise<User[]|null> {
  try {
    const response = await api.get<User[]>('/user/');
    return response.data;
  } catch (error) {
    console.error('Failed to get users:', error);
    return null;
  }
};

export async function registerUser(data: PartialUserModel): Promise<[User | null, string | null]> {
  try {
    const response = await api.post<User>('/auth/register/', {
      full_name: data.name,
      document: data.document,
      email: data.email,
      password: data.password,
      user_kind: data.kind,
    });

    return [response.data, null];
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.status != 400) {
        return [null, 'Falha ao adicionar usuário. Tente novamente'];
      }

      const errRes = err.response!.data!;
      if (errRes['document']) {
        return [null, 'CPF já existe'];
      } else if (errRes['email']) {
        return [null, 'E-mail já em uso ou inválido'];
      } else {
        return [null, 'Erro desconhecido!'];
      }
    }
  }

  return [null, '???'];
}

export async function editUser(userId: number, data: PartialUserModel): Promise<[User | null, string | null]> {
  try {
    const response = await api.patch<User>(`/user/${userId}/`, {
      full_name: data.name,
      document: data.document,
      email: data.email,
      password: data.password,
      user_kind: data.kind,
    });

    return [response.data, null];
  } catch (error) {
    console.error('Failed to edit user', error);
    if (axios.isAxiosError(error)) {
      if (error.status != 400) {
        return [null, 'Falha ao editar usuário. Tente novamente'];
      }

      const errRes = error.response!.data!;
      if (errRes['document']) {
        return [null, 'CPF já existe'];
      } else if (errRes['email']) {
        return [null, 'E-mail já em uso ou inválido'];
      } else {
        return [null, 'Erro desconhecido!'];
      }
    }
  }

  return [null, '?'];
}

export async function deleteUser(userId: number): Promise<[number | null, string | null]> {
  try {
    await api.delete(`/user/${userId}/`);
    return [userId, null];
  } catch (error) {
    return [null, 'Failed to delete user'];
  }
}

export async function changePassword(userId: number, newPassword: string): Promise<boolean> {
  try {
    api.post(`/user/${userId}/passwd/`, {
      password: newPassword,
      confirm_password: newPassword,
    });
    return true;
  } catch {
    return false;
  }
}