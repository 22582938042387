import { Box, HStack, FormControl, FormLabel, Select, Input, Flex, Spinner, Alert, AlertIcon, AlertDescription, Button, ScaleFade } from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ReactInputMask } from '../components/InputMask';
import { Address } from '../models/Address';

export const BrazilStates: string[] = [
  'AC', 'AL', 'AP',
  'AM', 'BA', 'CE',
  'GO', 'MA', 'PA',
  'PB', 'PR', 'PE',
  'PI', 'RO', 'RR',
  'SE', 'TO', 'DF',
  'ES', 'MT', 'MG',
  'SC', 'SP', 'RJ',
  'MS', 'RN', 'RS',
];

export function AddressPicker(props: {
  onAddAddress: (a: Address) => void,
}) {
  const [cep, setCep] = useState('');

  const [hasCep, setHasCep] = useState(false);
  const [logradouro, setLogradouro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [numero, setNumero] = useState('');

  const [error, setError] = useState('');
  const [btnEnabled, setBtnEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [everything, setEverything] = useState(false);

  async function fetchAddress(cep: string) {
    setLoading(true);
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;

      if (data['erro']) {
        setError('CEP não encontrado');
        setShouldBeDisabled(true);
        return;
      }

      setLogradouro(data['logradouro']);
      setCidade(data['localidade']);
      setEstado(data['uf']);
      setNumero('');
      setShouldBeDisabled(false);
      setShow(true);
    } catch (error) {
      setError('Erro ao buscar o CEP');
      setShouldBeDisabled(false);
      setShow(false);
    } finally {
      setLoading(false);
    }
  }

  const clear = () => {
    setLogradouro('');
    setCidade('');
    setEstado('SP');
    setError('');
    setNumero('');
  };

  useEffect(() => {
    const trimmedCep = cep.replace(/[^\d]+/g, '').replace('-', '');

    if (trimmedCep.length == 8) {
      clear();
      fetchAddress(trimmedCep);
    }
  }, [cep]);

  useEffect(() => {
    if (!logradouro || !cidade || !estado || !numero) {
      setBtnEnabled(false);
    } else {
      setBtnEnabled(true);
    }
  }, [logradouro, cidade, estado, numero]);

  useEffect(() => {
    if (!hasCep) {
      clear();
      setCep('');
      setShow(true);
      setShouldBeDisabled(false);
    } else {
      setShow(false);
      setShouldBeDisabled(true);
      clear();
    }
  }, [hasCep]);

  const [shouldBeDisabled, setShouldBeDisabled] = useState(true);

  const addAddress = () => {
    const addr: Address = {
      cep: cep,
      logradouro: logradouro,
      cidade: cidade,
      numero: numero,
      uf: estado,
      enabled: true,
    };

    props.onAddAddress(addr);
    clear();
    setCep('');
    setShow(false);
    setEverything(false);
  };

  return (
    <Box borderRadius={5} py={2} mb={3}>
      {!everything && <>
        <Flex width={'100%'}>
          <Button colorScheme={'cyan'} color={'whiteAlpha.900'}
            onClick={() => {
              setEverything(true);
              setHasCep(false);
              clear();
            }}>Novo endereço</Button>
        </Flex>
      </>}
      {/* @ts-ignore */}
      {everything && <ScaleFade in key={everything}>
        <HStack>
          <FormControl mb={3}>
            <FormLabel marginBottom={0}>Tipo de Endereço</FormLabel>
            <Select value={String(hasCep)} onChange={(e) => setHasCep(e.target.value === 'true' ? true : false)}>
              <option value={'false'}>Sem CEP/Rodovia</option>
              <option value={'true'}>Com CEP</option>
            </Select>
          </FormControl>
          {hasCep &&
          <FormControl mb={3} isDisabled={!hasCep}>
            <FormLabel marginBottom={0}>Busca CEP</FormLabel>
            <Input type='text' placeholder='CEP'
              as={ReactInputMask} mask={'99999-999'} value={cep}
              onChange={(e) => setCep(e.target.value)} />
          </FormControl>}
        </HStack>

        {loading && <Flex width={'100%'} justifyContent={'center'}><Spinner /></Flex>}

        {!loading &&
          // @ts-ignore
          <ScaleFade in key={show}>
            <HStack>
              <FormControl mb={2}>
                <FormLabel marginBottom={0}>{hasCep ? 'Logradouro' : 'Rodovia'}</FormLabel>
                <Input type='text' placeholder={hasCep ? 'Logradouro' : 'Nome da rodovia'}
                  disabled={shouldBeDisabled}
                  value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
              </FormControl>
              <FormControl mb={2} width={'35%'}>
                <FormLabel marginBottom={0}>{hasCep ? 'Nº' : 'KM'}</FormLabel>
                <Input type='text'
                  disabled={shouldBeDisabled}
                  value={numero} onChange={(e) => setNumero(e.target.value)} />
              </FormControl>
            </HStack>
            <HStack>
              <FormControl mb={2}>
                <FormLabel marginBottom={0}>Cidade</FormLabel>
                <Input type='text' placeholder='Cidade'
                  disabled={shouldBeDisabled}
                  value={cidade} onChange={(e) => setCidade(e.target.value)} />
              </FormControl>
              <FormControl mb={2} width={'35%'}>
                <FormLabel mb={0}>UF</FormLabel>
                <Select value={estado} onChange={(e) => setEstado(e.target.value)}>
                  {BrazilStates.map(s => <option value={s}>{s}</option>)}
                </Select>
              </FormControl>
            </HStack>
            {error &&
              <Alert status='error' borderRadius={5} mb={3}>
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>}
            <Flex width={'100%'}>
              <Button ml={'auto'} colorScheme='red' variant='ghost' onClick={() => {
                clear();
                setEverything(false);
              }}>
                Cancelar
              </Button>
              <Button ml={2} colorScheme='cyan' color={'whiteAlpha.900'}
                onClick={addAddress} disabled={!btnEnabled}>
                Adicionar endereço
              </Button>
            </Flex>
          </ScaleFade>
        }
      </ScaleFade>}
    </Box >
  );
}