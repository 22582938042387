import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, HStack, FormControl, FormLabel, Input, Select, Textarea, Checkbox, Alert, AlertIcon, AlertDescription, ModalFooter, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { api } from '../../lib/api';
import { MaterialData } from '../../models/MaterialData';
import { Project } from '../../models/Project';
import { materialUnit, timeFrom, timeNow } from '../../lib/util';
import { DatePicker } from '../DatePicker';
import { CustomNumberInput } from '../CustomNumberInput';

export interface MaterialModalMode {
  material: MaterialData | null,
  type: 'create' | 'edit',
}

export function MaterialModal(props: {
  project: Project,
  mode: MaterialModalMode,
  isOpen: boolean,
  onClose: () => void,
  onAddMaterial: (p: MaterialData) => void,
  onDeleteMaterial: (p: MaterialData) => void,
  onEditMaterial: (p: MaterialData) => void,
}) {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(timeNow());

  const [subject, setSubject] = useState('steel');
  const [quantity, setQuantity] = useState(0);

  const [notes, setNotes] = useState('');
  const [correct, setCorrect] = useState(true);

  const [error, setError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  async function createMaterialDelivery() {
    try {
      setButtonLoading(true);
      const response = await api.post(`/project/${props.project.id}/rawmaterials/`, {
        project: props.project.id,
        delivered_at: date.toISOString(),
        subject: subject,
        total_amount: quantity,
        is_correct: correct,
        notes: notes,
      });

      props.onAddMaterial({
        ...response.data,
        delivered_at: new Date(response.data.delivered_at),
      });
      props.onClose();
    } catch (error) {
      console.error('Failed to create material delivery', error);
    } finally {
      setButtonLoading(false);
    }
  }

  async function editMaterialDelivery() {
    try {
      setButtonLoading(true);
      const response = await api.patch(`/project/${props.project.id}/rawmaterials/${props.mode.material!.id}/`, {
        delivered_at: date.toISOString(),
        subject: subject,
        total_amount: quantity,
        is_correct: correct,
        notes: notes,
      });
      props.onEditMaterial({
        ...response.data,
        delivered_at: new Date(response.data.delivered_at)
      });
      props.onClose();
    } catch (error) {
      console.error('Failed to edit material delivery', error);
    } finally {
      setButtonLoading(false);
    }
  }

  async function deleteMaterialDelivery() {
    try {
      await api.delete(`/project/${props.project.id}/rawmaterials/${props.mode.material!.id}/`);
      props.onDeleteMaterial(props.mode.material!);
      props.onClose();
    } catch (error) {
      console.error('Failed to delete material delivery', error);
    }
  }

  async function handleConfirmButton() {
    setError('');

    if (correct && quantity <= 0) {
      setError('Quantidade precisa ser maior que zero');
      return;
    }

    if (!time || !date) {
      setError('Data e hora são obrigatórios');
      return;
    }

    const [hours, minutes] = time.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);

    if (date > new Date()) {
      setError('Data de entrega não pode ser no futuro');
      return;
    }

    if (date < props.project.start_date) {
      setError('Data de entrega precisa ser posterior ao ínicio do projeto');
      return;
    }

    if (props.mode.type === 'create') {
      await createMaterialDelivery();
    } else {
      await editMaterialDelivery();
    }
  }

  useEffect(() => {
    setError('');

    if (props.mode.type === 'create') {
      setTime(timeNow());
      setSubject('steel');
      setNotes('');
      setCorrect(false);
      setDate(new Date());
      setQuantity(0);
    } else {
      const m = props.mode.material!;
      setTime(timeFrom(m.delivered_at));
      setSubject(m.subject);
      setNotes(m.notes);
      setCorrect(m.is_correct);
      setDate(m.delivered_at);
      setQuantity(m.total_amount);
    }
  }, [props.mode]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay></ModalOverlay>
      <ModalContent>
        <ModalHeader>{props.mode.type === 'create' ? 'Registrar' : 'Editar'} Entrega de Matéria Prima</ModalHeader>
        <ModalBody>
          <HStack mb={3}>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Data</FormLabel>
              <DatePicker value={date} onChange={(d) => setDate(d!)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Hora</FormLabel>
              <Input type='time' value={time} onChange={(e) => setTime(e.target.value)} />
            </FormControl>
          </HStack>
          <HStack>
            <FormControl isRequired mb={3}>
              <FormLabel marginBottom={0}>Material</FormLabel>
              <Select variant={'filled'} value={subject} onChange={(e) => setSubject(e.target.value)}>
                <option value={'steel'}>Aço</option>
                <option value={'concrete'}>Concreto</option>
                <option value={'connection_kit'}>Kit de ligação</option>
              </Select>
            </FormControl>
            <FormControl isRequired mb={3}>
              <FormLabel marginBottom={0}>Quantidade</FormLabel>
                <CustomNumberInput
                  type={'with-unit'}
                  unit={subject === 'steel' ? 'armações' : materialUnit(subject)}
                  value={quantity}
                  onChange={(n) => { setQuantity(n); }}
                  autoFocus
                />
            </FormControl>
          </HStack>
          <FormControl mb={3}>
            <FormLabel marginBottom={0}>Notas</FormLabel>
            <Textarea autoFocus value={notes} onChange={(e) => setNotes(e.target.value)}></Textarea>
          </FormControl>
          <FormControl isRequired mb={3}>
            <Checkbox isChecked={correct} onChange={(e) => setCorrect(Boolean(e.target.checked))}>
              <Text mb={-0.5} fontWeight={'semibold'}>
                Entrega Correta
              </Text>
            </Checkbox>
          </FormControl>
          {error &&
            <Alert status='error' borderRadius={5}>
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
        </ModalBody>
        <ModalFooter>
          {props.mode.type === 'edit' && <Button variant='ghost' colorScheme='red' mr={'auto'}
            onClick={async () => {
              const ok = confirm('Tem certeza que deseja deletar esse registro?');
              if (ok) {
                await deleteMaterialDelivery();
              }
            }}
          >Excluir</Button>}
          <Button colorScheme='blue' onClick={handleConfirmButton} isLoading={buttonLoading}>
            {props.mode.type === 'create' ? 'Adicionar' : 'Salvar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
