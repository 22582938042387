import { api } from '../api';
import { Project } from '../../models/Project';
import { Address } from '../../models/Address';

export interface PartialProjectModel {
  contractCode?: string,
  name?: string,
  clientName?: string,
  startDate?: Date,
  endDate?: Date,
  requiredBarriers?: number,
  priceConcrete?: number,
  priceSteel?: number,
  priceInstallKit?: number,
  totalPrice?: number,
  priceMeterBarrier?: number,
  priceInstall?: number,
  completed?: boolean,
}

export async function createProject(data: PartialProjectModel, addresses: Address[]): Promise<[Project | null, string | null]> {
  try {
    data.startDate!.setHours(0);
    data.endDate!.setHours(23, 59, 59);

    const projectResponse = await api.post<Project>('/project/', {
      code: data.contractCode,
      name: data.name,
      client_name: data.clientName,
      start_date: data.startDate!.toISOString(),
      end_date: data.endDate!.toISOString(),
      completed: false,
      required_barriers: data.requiredBarriers,
      price_concrete: data.priceConcrete,
      price_steel: data.priceSteel,
      price_install_kit: data.priceInstallKit,
      total_price: data.totalPrice,
      price_meter_barrier: data.priceMeterBarrier,
      price_install: data.priceInstall === 0 ? null : data.priceInstall,
    });

    const project: Project = {
      ...projectResponse.data,
      start_date: new Date(projectResponse.data.start_date),
      end_date: new Date(projectResponse.data.end_date),
    };

    const addressesResponse = await api.post(`/project/${project.id}/addresses/`,
      addresses.map((a) => {
        return {
          project: project.id,
          cep: a.cep,
          logradouro: a.logradouro,
          cidade: a.cidade,
          numero: a.numero,
          uf: a.uf,
          enabled: a.enabled,
        };
      }));

    project.addresses = addressesResponse.data as Address[];
    return [project, null];
  } catch (error) {
    return [null, 'Um problema ocorreu na criação do projeto, tente novamente mais tarde'];
  }
}

export async function editProject(project: Project, data: PartialProjectModel, activeAddresses: number[], newAddresses: Address[]): Promise<[Project | null, string | null]> {
  try {
    if (activeAddresses.length === 0) {
      return [null, 'Ao menos um endereço precisa estar ativo'];
    }

    for (const addr of project.addresses!) {
      await api.post(`/project/${project.id}/addresses/${addr.id}/`, {
        enabled: (activeAddresses.includes(addr.id!))
      });
    }

    const response = await api.patch<Project>(`/project/${project.id}/`, {
      code: data.contractCode,
      name: data.name,
      client_name: data.clientName,
      start_date: data.startDate!.toISOString(),
      end_date: data.endDate!.toISOString(),
      completed: data.completed,
      required_barriers: data.requiredBarriers,
      price_concrete: data.priceConcrete,
      price_steel: data.priceSteel,
      price_install_kit: data.priceInstallKit,
      total_price: data.totalPrice,
      price_meter_barrier: data.priceMeterBarrier,
      price_install: data.priceInstall === 0 ? null : data.priceInstall,
    });

    const editedProject: Project = {
      ...response.data,
      start_date: new Date(response.data.start_date),
      end_date: new Date(response.data.end_date),
    };

    if (newAddresses.length > 0) {
      const addressesResponse = await api.post(`/project/${project.id}/addresses/`,
        newAddresses.map((a) => {
          return {
            project: project.id,
            cep: a.cep,
            logradouro: a.logradouro,
            cidade: a.cidade,
            numero: a.numero,
            uf: a.uf,
            enabled: a.enabled,
          };
        }));

      editedProject.addresses = [...addressesResponse.data, ...project.addresses!];
    }

    return [editedProject, null];
  } catch (error) {
    return [null, 'Um problema ocorreu na edição do project'];
  }
}