export function validateCpf(cpf: string): [boolean, string | null] {
  const doc = cpf.replace(/[^\d]+/g, '');
  if (doc.length !== 11 || /^(\d)\1+$/.test(doc)) return [false, 'CPF precisa ter 11 ou mais dígitos'];

  function calcDigit(s: string, weight: number): number {
    const sum = [...s]
      .map(Number)
      .reduce((sum, e, i) => sum + e * (weight - i), 0);

    const remainder = (sum * 10) % 11;
    return remainder === 10 ? 0 : remainder;
  }

  const va = calcDigit(doc.slice(0, 9), 10);
  const vb = calcDigit(doc.slice(0, 10), 11);

  const valid = va === Number(doc[9]) && vb === Number(doc[10]);
  if (!valid) return [false, 'CPF Inválido'];
  return [true, null];
}

export function validateCnpj(cnpj: string): boolean {
  const doc = cnpj.replace(/[^\d]+/g, '');
  if (doc.length !== 14 || /^(\d)\1+$/.test(doc)) return false;

  function calcDigit(s: string, weights: number[]): number {
    const sum = [...s]
      .map(Number)
      .reduce((acc, e, i) => acc + e * weights[i], 0);

    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  }

  const va = calcDigit(doc.slice(0, 12), [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
  const vb = calcDigit(doc.slice(0, 13), [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

  return va === Number(doc[12]) && vb === Number(doc[13]);
}

export function userKindToDisplay(userKind: string) {
  switch (userKind) {
    case 'admin': return 'Administrador';
    case 'office': return 'Escritório';
    case 'worker': return 'Operário';
    default: {
      console.error('Unknow user kind: ', userKind);
      return '???';
    }
  }
}

export function materialToDisplay(material: string) {
  switch (material) {
    case 'steel': return 'Aço';
    case 'concrete': return 'Concreto';
    case 'connection_kit': return 'Kit de ligação';
    default: {
      console.error('Unknow material type: ', material);
      return '???';
    }
  }
}

export function materialUnit(material: string) {
  switch (material) {
    case 'steel': return 'kg';
    case 'concrete': return 'm³';
    case 'connection_kit': return 'unidades';
    default: return '';
  }
}

export function transportToDisplay(s: string) {
  if (s === 'both') return 'Ambos';
  else return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getHighlightedText(text: string, filter: string, regExp: RegExp) {
  const parts = text.split(regExp);
  return (
    <span>
      {parts.map((part, i) =>
        <span key={i} className={part.toLowerCase() === filter.toLowerCase() ? 'highlight' : ''}>{part}</span>)}
    </span>
  );
}

export function formatDate(date: Date, time: boolean = true): string {
  if (!time) return date.toLocaleDateString();
  else return `${date.toLocaleDateString()} ${date.toLocaleTimeString().split(':').slice(0, 2).join(':')}`;
}

export function formatNumber(n: number, places: number = 2, autoHidePlaces = false) {
  if (autoHidePlaces && !((n - Math.floor(n)) !== 0)) {
    return n.toFixed(0).replace('.', ',');
  }
  return n.toFixed(places).replace('.', ',');
}

export function timeFrom(d: Date): string {
  const [hours, minutes] = d.toTimeString().split(':');
  return `${hours}:${minutes}`;
}

export function timeNow(): string {
  return timeFrom(new Date());
}

export function helperValidator(fields: {
  name: string;
  value: any;
  validator?: (value: any) => [boolean, string | null];
}[]
): [boolean, string | null] {
  for (const f of fields) {
    if (!f.value) {
      return [false, `Campo '${f.name}' é obrigatório`];
    }

    if (f.validator) {
      const [ok, error] = f.validator(f.value);
      if (!ok) {
        return [false, error];
      }
    }
  }

  return [true, null];
}