import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, Flex, ScaleFade } from '@chakra-ui/react';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { useAuth } from '../lib/hooks/useAuth';
import { useIsMobile } from '../lib/mobileHook';

export function RootPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, loading } = useAuth();
  const { isMobile, width } = useIsMobile();

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    setShowSidebar(false);

    if (loading) return;

    if (user && location.pathname === '/') {
      if (user.user_kind == 'admin') {
        navigate('/workers');
      } else {
        navigate('/projects');
      }
    }

    if (user && location.pathname === '/workers') {
      if (user.user_kind != 'admin') {
        navigate('/projects');
      }
    }

    if (!user) {
      navigate('/login');
    }
  }, [location.pathname, user, loading])

  return (
    !loading && user != null &&
      <Box maxWidth={'1300px'} margin={'auto'}>
        <Header onMenuClick={() => setShowSidebar(true)}></Header>
        <Flex>
          <Sidebar show={showSidebar} onClose={() => setShowSidebar(false)}/>
          <Box width={isMobile() ? '100%' : `${width - 180}px`} flexShrink={Number.MAX_SAFE_INTEGER}>
            <ScaleFade in key={location.pathname}>
              <Outlet />
            </ScaleFade>
          </Box>
        </Flex>
      </Box>
    );
}
