import { Box, VStack, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';
import { FiFolder, FiSettings, FiUsers, FiX } from 'react-icons/fi';
import { useAuth } from '../lib/hooks/useAuth';
import { ChangePasswordModal } from './modals/ChangePasswordModal';
import { useIsMobile } from '../lib/mobileHook';

export function Sidebar(props: { show: boolean, onClose: () => void; }) {
  const location = useLocation();
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isMobile } = useIsMobile();

  return (
    <Box
      width='180px'
      bg='gray.800'
      color='white'
      p={5}
      minH={isMobile() ? '100vh' : '600px'}
      flexShrink={0}
      borderBottomLeftRadius={isMobile() ? 0 : 10}
      display={isMobile() ? (props.show ? undefined : 'none') : undefined}
      position={isMobile() ? 'absolute' : undefined}
      left={isMobile() ? 0 : undefined}
      top={isMobile() ? 0 : undefined}
      zIndex={99}
    >
      <ChangePasswordModal isOpen={isOpen} onClose={onClose}></ChangePasswordModal>
      <VStack align='start' spacing={4} height={'100%'}>
        {isMobile() && <Box ml={'auto'} mb={5} onClick={props.onClose}><FiX size={25} /></Box>}
        {user && user.user_kind == 'admin' &&
          <HStack alignItems='center' cursor={'pointer'}>
            <Icon as={FiUsers} mt={-1} />
            <Text fontWeight={(location.pathname == '/workers') ? 'semibold' : 'normal'}>
              <Link to={'/workers'}>
                Funcionários
              </Link>
            </Text>
          </HStack>}
        <HStack alignItems='center' cursor={'pointer'}>
          <Icon as={FiFolder} mt={-1} />
          <Text fontWeight={(location.pathname.startsWith('/project')) ? 'semibold' : 'normal'}>
            <Link to={'/projects'}>Projetos</Link>
          </Text>
        </HStack>
        <HStack alignItems='center' cursor={'pointer'}
          onClick={onOpen}>
          <Icon as={FiSettings} mt={-1} />
          <Text>
            Alterar senha
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
}