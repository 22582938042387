import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, HStack, FormControl, FormLabel, Input, Select, Alert, AlertIcon, AlertDescription, ModalFooter, Button, Tooltip, Box, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { api } from '../../lib/api';
import { InstallData } from '../../models/InstallData';
import { Project } from '../../models/Project';
import { DatePicker } from '../DatePicker';
import { timeFrom, timeNow, transportToDisplay } from '../../lib/util';
import { FiHelpCircle, FiX } from 'react-icons/fi';

export interface InstallModalMode {
  install: InstallData | null,
  type: 'create' | 'edit',
}

export interface InstallTransport {
  type: string,
  nfe: string,
  quantity: number,
  barCodes: string[],
};

export function InstallsModal(props: {
  project: Project,
  mode: InstallModalMode,
  isOpen: boolean,
  onClose: () => void,
  onAddInstall: (p: InstallData) => void,
  onDeleteInstall: (p: InstallData) => void,
  onEditInstall: (p: InstallData) => void,
}) {
  const [time, setTime] = useState(timeNow());
  const [date, setDate] = useState(new Date());
  const [deliveredBarriers, setDeliveredBarriers] = useState(1);
  const [transport, setTransport] = useState('carreta');
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [nfe, setNfe] = useState<string>('');

  const [error, setError] = useState('');
  const [transError, setTransError] = useState('');
  const [formButtonLoading, setFormButtonLoading] = useState(false);

  const [transportData, setTransportData] = useState<InstallTransport[]>([]);
  const [totalBarriers, setTotalBarriers] = useState(0);
  const [barCodesInput, setBarCodesInput] = useState<string>('');

  const [barCodes, setBarCodes] = useState<string[]>([]);

  useEffect(() => {
    setTotalBarriers(transportData.reduce((sum, e) => sum + e.quantity, 0));
  }, [transportData]);

  useEffect(() => {
    setBarCodes(barCodesInput.split(';').filter(Boolean));
  }, [barCodesInput]);

  async function handleConfirmButton() {
    setError('');
    setTransError('');

    if (!time || !date) {
      return setError('Data e hora são obrigatórios');
    }

    if (totalBarriers <= 0) {
      setError('Quantidade de barreiras entregues precisa ser maior que zero');
      return;
    }

    const [hours, minutes] = time.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);

    if (date > new Date()) {
      setError('Data de entrega não pode ser no futuro');
      return;
    }

    if (date < props.project.start_date) {
      setError('Data de entrega precisa ser posterior ao ínicio do projeto');
      return;
    }

    if (props.mode.type === 'create') {
      await createInstall();
    } else {
      await editInstall();
    }
  }

  async function createInstall() {
    try {
      setFormButtonLoading(true);
      const response = await api.post(`/project/${props.project.id}/deliveries/`, {
        project: props.project.id,
        address: address === 'none' ? null : Number(address),
        transported_at: date,
        data: JSON.stringify(transportData),
      });

      const install = {
        ...response.data as InstallData,
        transported_at: new Date(response.data.transported_at)
      };

      props.onAddInstall(install);
      props.onClose();
    } catch (error) {
      console.error('Failed to create barrier install', error);
    } finally {
      setFormButtonLoading(false);
    }
  }

  async function editInstall() {
    try {
      setFormButtonLoading(true);
      const response = await api.patch(`/project/${props.project.id}/deliveries/${props.mode.install!.id}/`, {
        address: address === 'none' ? null : Number(address),
        transported_at: date,
        data: JSON.stringify(transportData),
      });

      const install = {
        ...response.data as InstallData,
        transported_at: new Date(response.data.transported_at)
      };

      props.onEditInstall(install);
      props.onClose();
    } catch (error) {
      console.error('Failed to create barrier install', error);
    } finally {
      setFormButtonLoading(false);
    }
  }

  async function deleteInstall() {
    try {
      await api.delete(`/project/${props.project.id}/deliveries/${props.mode.install!.id}/`);
      props.onDeleteInstall(props.mode.install!);
      props.onClose();
    } catch (error) {
      console.error('Failed to delete material delivery', error);
    }
  }

  useEffect(() => {
    setError('');
    setTransError('');
    if (props.mode.type === 'create') {
      setDeliveredBarriers(0);
      setTime(timeNow());

      if (props.project.price_install !== null) {
        const defaultAddr = props.project.addresses![0].id;
        setAddress(String(defaultAddr));
      } else {
        setAddress('none');
      }

      setNfe('');
      setTransport('carreta');
      setDate(new Date());
      setTransportData([]);
    } else {
      const i = props.mode.install!;
      
      setDeliveredBarriers(0);
      setNfe('');
      setTime(timeFrom(i.transported_at));
      
      if (props.project.price_install !== null) {
        let address = props.project.addresses?.filter(a => a.id == i.address)[0];
        setAddress(String(address!.id));
      } else {
        setAddress('none');
      }

      setDate(i.transported_at);

      setTransportData(JSON.parse(i.data));
    }
  }, [props.isOpen, props.mode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(''); setTransError('');
    }, 1000 * 5);

    return () => clearTimeout(timeout);
  }, [error, transError]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay></ModalOverlay>
      <ModalContent>
        <ModalHeader>{props.mode.type === 'create' ? 'Registrar' : 'Editar'} Entrega de Barreira</ModalHeader>
        <ModalBody>
          <HStack mb={3}>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Data</FormLabel>
              <DatePicker value={date} onChange={(d) => setDate(d!)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Hora</FormLabel>
              <Input type='time' value={time} onChange={(e) => setTime(e.target.value)} />
            </FormControl>
          </HStack>

          <Box p={2} borderRadius={10} borderColor={'gray.200'} borderWidth={'1px'} mb={3}>
            <HStack>
              <FormControl mt={1} isRequired mb={3} width={'60%'}>
                <FormLabel mb={0}>Transporte</FormLabel>
                <Select variant={'filled'} value={transport} onChange={(e) => setTransport(e.target.value)}>
                  <option value={'carreta'}>Carreta</option>
                  <option value={'munk'}>Munk</option>
                  <option value={'both'}>Ambos</option>
                </Select>
              </FormControl>
              <FormControl isRequired mb={3}>
                <FormLabel margin={0}>
                  Nota Fiscal
                </FormLabel>
                <Input type='text' placeholder='Nota Fiscal' autoFocus
                  value={nfe} onChange={(e) => setNfe(e.target.value)} />
              </FormControl>
            </HStack>

            <FormControl isRequired mb={3}>
              <FormLabel margin={0}>Barrerias transportadas</FormLabel>
              <Input type='number' placeholder='Barreiras transportadas'
                value={String(deliveredBarriers)} onChange={(e) => {
                  setDeliveredBarriers(parseInt(e.target.value));
                }} />
            </FormControl>

            <FormControl isRequired mb={3}>
              <HStack>
                <FormLabel margin={0}>
                  Códigos de Barras ({barCodes.length}/{Number.isNaN(deliveredBarriers) ? 0 : deliveredBarriers})
                </FormLabel>
                <Tooltip label={'Múltiplos códigos permitidos separados por ;'}>
                  <Box mb={1}>
                    <FiHelpCircle />
                  </Box>
                </Tooltip>
              </HStack>
              <Input type='text' placeholder='Códigos de Barras'
                value={barCodesInput} onChange={(e) => setBarCodesInput(e.target.value)} />
            </FormControl>

            {transError &&
              <Alert status='error' borderRadius={5} mb={3}>
                <AlertIcon />
                <AlertDescription>{transError}</AlertDescription>
              </Alert>}
            <Flex width={'100%'} mb={1}>
              <Button colorScheme={'blue'} ml={'auto'} size={'md'} onClick={() => {
                setTransError('');

                if (!nfe) {
                  return setTransError('Nota fiscal é obrigatória');
                }

                if (barCodes.length > deliveredBarriers) {
                  return setTransError('Quantidade de códigos de barras é maior que a quantidade de barreiras');
                }

                if (barCodes.length < deliveredBarriers) {
                  return setTransError('Nem todos códigos de barras foram inseridos');
                }

                setTransportData([...transportData, {
                  type: transport,
                  quantity: deliveredBarriers,
                  nfe: nfe,
                  barCodes: barCodes
                }]);

                setNfe('');
                setBarCodesInput('');
                setDeliveredBarriers(0);
                setError('');
              }}>Adicionar transporte</Button>
            </Flex>
          </Box>

          <Box maxH={'200px'} overflowY={'auto'}>
            {transportData.map((t, i) => (
              <Box key={i} p={2} bg={'gray.50'} borderRadius={5} mb={3}>
                <HStack>
                  <Box width={'100%'}>
                    <Text mr={'auto'}><b>Tipo</b>: {transportToDisplay(t.type)} <b>Qtd</b>: {t.quantity}</Text>
                    <Text mr={'auto'}><b>NF</b>: {t.nfe} - <b>CB</b>: {t.barCodes.join(', ')}</Text>
                  </Box>
                  <Button colorScheme='red' variant={'ghost'}
                    onClick={() => {
                      const td = transportData.splice(i - 1, 1);
                      setTransportData(td);
                    }}
                  >
                    <FiX size={20} />
                  </Button>
                </HStack>
              </Box>
            ))}
          </Box>

          <FormControl isRequired mb={3}>
            <FormLabel mb={0}>Endereço</FormLabel>
            <Select variant={'filled'} value={address} onChange={(e) => setAddress(e.target.value)}>
              {!props.project.price_install && <option value={'none'}>Não especificado</option>}
              {props.project.addresses?.filter(a => a.enabled).map((a, i) => {
                return (<option key={i} value={String(a.id)}>{a.logradouro} {a.numero}</option>);
              })}
            </Select>
          </FormControl>

          {error &&
            <Alert status='error' borderRadius={5}>
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
        </ModalBody>
        <ModalFooter>
          {props.mode.type === 'edit' && <Button variant='ghost' colorScheme='red' mr={'auto'}
            onClick={async () => {
              const ok = confirm('Tem certeza que deseja deletar esse registro?');
              if (ok) {
                await deleteInstall();
              }
            }}
          >Excluir</Button>}

          <Button colorScheme='blue' onClick={handleConfirmButton} isLoading={formButtonLoading}>
            {props.mode.type === 'create' ? 'Adicionar' : 'Salvar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
