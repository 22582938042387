import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormControl, FormLabel, Input, Select, Alert, AlertIcon, AlertDescription, ModalFooter, Button, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/hooks/useAuth';
import { helperValidator, validateCpf } from '../../lib/util';
import { User } from '../../models/User';
import { ReactInputMask as InputMask } from '../InputMask';
import { changePassword, deleteUser, editUser, registerUser } from '../../lib/repositories/usersRepository';

export interface WorkerModalProps { user: User | null, type: 'create' | 'edit'; }

export function WorkerModal(
  props: {
    isOpen: boolean,
    onClose: () => void,
    mode: WorkerModalProps,
    onUserAdd: (newUser: User) => void,
    onUserDelete: (user: User) => void,
    onUserEdit: (user: User) => void,
  }
) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [kind, setKind] = useState('');
  const [password, setPassword] = useState('');

  const [modalDirty, setModalDirty] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState('');

  const { user } = useAuth();

  async function handleConfirmButton() {
    setError('');
    const [ok, error] = helperValidator([
      { name: 'Nome completo', value: name },
      { name: 'E-mail', value: email, validator: validateEmail },
      { name: 'CPF', value: document, validator: validateCpf },
      { name: 'Tipo funcionário', value: kind },
    ]);

    if (!ok) return setError(error!);

    setButtonLoading(true);
    if (props.mode.type === 'create') {
      try {
        const [passwordOk, passwordError] = helperValidator([
          { name: 'Senha', value: password, validator: lengthValidator(6, 'Senha') }
        ]);

        if (!passwordOk) return setError(passwordError!);

        const [user, error] = await registerUser({ name, email, document, password, kind });
        if (error != null) {
          return setError(error);
        }

        props.onUserAdd(user!);
        props.onClose();
      } finally {
        setButtonLoading(false);
      }
    } else if (props.mode.type === 'edit' && editMode) {
      try {
        if (password !== '') {
          const [passwordOk, passwordError] = helperValidator([
            { name: 'Senha', value: password, validator: lengthValidator(6, 'Senha') }
          ]);

          if (!passwordOk) return setError(passwordError!);

          if (!changePassword(props.mode.user!.id, password)) {
            return setError('Falha ao editar a senha, tente novamente mais tarde');
          }
        }

        const [user, error] = await editUser(props.mode.user!.id, { name, email, document, password, kind });
        if (error != null) {
          return setError(error);
        }

        props.onUserEdit(user!);
        props.onClose();
      } finally {
        setButtonLoading(false);
      }
    }
  }

  useEffect(() => {
    if (props.mode.type == 'create') {
      setName('');
      setEmail('');
      setDocument('');
      setKind('');
      setPassword('');

      setError('');
      setModalDirty(true);
      setEditMode(true);
    } else {
      const user = props.mode.user!;

      setName(user.full_name);
      setEmail(user.email);
      setDocument(user.document);
      setKind(user.user_kind);
      setPassword('');

      setError('');
      setModalDirty(false);
      setEditMode(false);
    }
  }, [props.mode]);

  useEffect(() => {
    if (props.mode.type !== 'edit') return;

    const w = props.mode.user;
    if (!w) return;

    let dirty =
      (name != w.full_name)
      || (email != w.email)
      || (document != w.document)
      || (kind != w.user_kind);

    dirty = (dirty) || (password != '');

    setModalDirty(dirty);
  }, [name, email, document, kind, password]);

  const inputStyle = { opacity: '1 !important' };

  async function onUserDelete() {
    let confirmed = confirm('Tem certeza que deseja deletar o usuário?\nEssa ação é irreversível!');
    if (confirmed) {
      confirmed = confirm('O usuário não poderá mais acessar o sistema.\nInformações inseridas por ele ainda estarão salvas.');
    }

    if (confirmed && props.mode.user!.id !== user!.id) {
      const [_, error] = await deleteUser(props.mode.user!.id);
      if (error != null) {
        return setError('Falha ao excluir o usuário, tente novamente');
      }

      props.onUserDelete(props.mode.user!);
      props.onClose();
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay></ModalOverlay>
      <ModalContent>
        <ModalHeader>
          {props.mode.type === 'edit'
            ? (editMode ? 'Editar' : 'Visualizar') : 'Cadastrar'}
          {' funcionário'}
        </ModalHeader>
        <ModalBody>
          <FormControl isRequired>
            <FormLabel marginBottom={0}>Nome completo</FormLabel>
            <Input type='text' placeholder='Nome completo' value={name}
              disabled={!editMode} sx={inputStyle}
              onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl marginTop={3} isRequired>
            <FormLabel marginBottom={0}>E-mail</FormLabel>
            <Input type='email' placeholder='E-mail' value={email}
              disabled={!editMode} sx={inputStyle}
              onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl marginTop={3} isRequired>
            <FormLabel marginBottom={0}>CPF</FormLabel>
            <Input type='text' placeholder='CPF' value={document}
              disabled={!editMode} sx={inputStyle}
              onChange={(e) => setDocument(e.target.value)}
              as={InputMask} mask={'***.***.***-**'} />
          </FormControl>
          <FormControl marginTop={3} isRequired>
            <FormLabel marginBottom={0}>Tipo de usuário</FormLabel>
            <Select disabled={!editMode}
              sx={inputStyle}
              placeholder='Selecione uma opção' isRequired onChange={(e) => setKind(e.target.value)} value={kind}
            >
              <option value='worker'>Operário</option>
              <option value='office'>Escritório</option>
              <option value='admin'>Administrador</option>
            </Select>
          </FormControl>
          {editMode &&
            <FormControl marginTop={3} isRequired={props.mode.type === 'create'}>
              <FormLabel marginBottom={0}>
                {props.mode.type == 'create' ? 'Senha' : 'Nova senha (opcional)'}
              </FormLabel>
              <Input type='password' placeholder='Senha' value={password}
                autoComplete='off'
                onChange={(e) => setPassword(e.target.value)} />
            </FormControl>}
          {error !== '' &&
            <Alert status='error' mt={5} borderRadius={5}>
              <AlertIcon />
              <AlertDescription><Text>{error}</Text></AlertDescription>
            </Alert>}
        </ModalBody>

        <ModalFooter>
          {props.mode.type === 'edit' && editMode && props.mode.user!.id !== user!.id &&
            <Button variant='ghost' colorScheme='red' mr={'auto'}
              onClick={onUserDelete}>Excluir</Button>
          }
          {!editMode &&
            <Button mr={'auto'} colorScheme='blue' variant='ghost'
              onClick={() => setEditMode(true)}>Editar</Button>}
          <Button colorScheme='red' onClick={props.onClose}>Cancelar</Button>
          {editMode &&
            <Button
              colorScheme='blue' onClick={handleConfirmButton}
              isLoading={buttonLoading}
              disabled={!modalDirty}
              ml={5}
            >Salvar</Button>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function validateEmail(email: string): [boolean, string | null] {
  if (!(email.split('@').filter(s => s).length >= 2))
    return [false, 'E-mail inválido'];
  return [true, null];
}

function lengthValidator(length: number, field: string) {
  return function (value: string): [boolean, string | null] {
    if (!(value.length >= length)) return [false, `${field} deve conter ${length} ou mais caracters`];
    else return [true, ''];
  };
}