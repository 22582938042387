import { Text, useDisclosure, Box, HStack, Button, Stack, Select, Progress, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { api } from '../../lib/api';
import { useIsMobile } from '../../lib/mobileHook';
import { InstallData } from '../../models/InstallData';
import { barriersToMeters, metersToBarriers, Project } from '../../models/Project';
import { formatDate, formatNumber, transportToDisplay } from '../../lib/util';
import { DatePicker } from '../DatePicker';
import { InstallModalMode, InstallsModal, InstallTransport } from '../modals/InstallsModal';


export function InstallsTab({ project }: { project: Project; }) {
  const [startDate, setStartDate] = useState(project.start_date);
  const [endDate, setEndDate] = useState(project.end_date);
  const [filterAddress, setFilterAddress] = useState('all');

  const [sortBy, setSortBy] = useState('recent');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [installs, setInstalls] = useState<InstallData[]>([]);
  const [filtered, setFiltered] = useState<InstallData[]>([]);

  async function fetchInstalls() {
    try {
      const response = await api.get<InstallData[]>(`/project/${project.id}/deliveries/`);
      setInstalls(response.data.map(i => {
        return {
          ...i,
          transported_at: new Date(i.transported_at)
        };
      }));
    } catch (error) {
      console.error('Failed to fetch installs', error);
    }
  }

  useEffect(() => {
    fetchInstalls();
  }, [project]);

  useEffect(() => {
    setFiltered(installs
      .filter(i => {
        if (i.transported_at >= endDate || i.transported_at <= startDate) return false;

        if (filterAddress !== 'all') {
          const aid = Number(filterAddress);
          if (i.address != aid) return false;
        }

        return true;
      })
      .sort((a, b) => sortBy === 'older'
        ? a.transported_at.getTime() - b.transported_at.getTime()
        : b.transported_at.getTime() - a.transported_at.getTime())
    );
  }, [installs, sortBy, startDate, endDate, filterAddress]);

  const [sumDeliveredBarries, setSumDeliveredBarries] = useState(0);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    let sum = 0;
    for (const install of filtered) {
      const data = JSON.parse(install.data) as InstallTransport[];
      const qtds = data.map(d => d.quantity);
      sum += qtds.reduce((sum, e) => sum + e);
    }

    setSumDeliveredBarries(sum);
  }, [filtered]);

  useEffect(() => {
    setPercent((sumDeliveredBarries / metersToBarriers(project.required_barriers)) * 100);
  }, [sumDeliveredBarries]);

  const { isMobile } = useIsMobile();

  const [canEdit, setCanEdit] = useState(true);
  useEffect(() => {
    setCanEdit(!project.completed);
  }, [project]);

  const [modalMode, setModalMode] = useState<InstallModalMode>({
    type: 'create', install: null
  });

  return (
    <Box>
      <InstallsModal project={project} isOpen={isOpen} onClose={onClose}
        mode={modalMode}
        onAddInstall={(i) => setInstalls([...installs, i])}
        onDeleteInstall={(ni) => setInstalls(installs.filter(i => i.id !== ni.id))}
        onEditInstall={(ni) => setInstalls(installs.map(i => i.id === ni.id ? ni : i))}
      />

      <HStack alignItems={'center'}>
        <Text fontSize={isMobile() ? 'xl' : '2xl'} fontWeight={900} mb={-1}>Entregas</Text>
        {canEdit
          ? <Button colorScheme='blue' ml={'auto'} onClick={() => {
            setModalMode({ type: 'create', install: null });
            onOpen();
          }}>Adicionar</Button>
          : <></>}
      </HStack>

      <Stack marginY={5} direction={isMobile() ? 'column' : 'row'}>
        <HStack>
          <DatePicker value={startDate} onChange={(d) => setStartDate(d ?? new Date())} />
          <DatePicker value={endDate} onChange={(d) => {
            d ??= new Date();
            d!.setHours(23, 59, 59);
            setEndDate(d);
          }} />
        </HStack>
        <Stack direction={'row'}>
          <Select variant={'filled'} maxWidth={'225px'} value={sortBy} onChange={(e) => setSortBy(e.target.value!)}>
            <option value={'recent'}>Mais recentes</option>
            <option value={'older'}>Mais antigos</option>
          </Select>
          <Select variant={'filled'} maxWidth={'225px'} value={filterAddress} onChange={(e) => setFilterAddress(e.target.value)}>
            <option value={'all'}>Todos endereços</option>
            {project.addresses?.map((a, i) => {
              return (<option key={i} value={String(a.id)}>{a.logradouro}, {a.numero}</option>);
            })}
          </Select>
        </Stack>
      </Stack>

      <Box background={'gray.100'} p={2} borderRadius={5} marginBottom={5}>
        <Text fontSize={'xl'}>
          <span style={{ fontWeight: 'bold' }}>Estatísticas </span>
          {isMobile() && <br />}
          ({formatDate(startDate, false)} - {formatDate(endDate, false)})
        </Text>
        <HStack spacing={0}>
          <Text pt={'3px'} mr={3}>
            <b>{sumDeliveredBarries}</b> barreiras entregues de <b>{formatNumber(metersToBarriers(project.required_barriers), 2, true)}</b> totais
          </Text>
          {isMobile() && <span style={{ marginLeft: 'auto' }}>({formatNumber(percent, 1, true)}%)</span>}
          {!isMobile() && <>
            <Progress borderRadius={5} flex={1} value={percent} backgroundColor={'gray.300'}
              colorScheme={percent >= 100 ? 'green' : 'blue'} />
            <Text pt={'3px'} ml={3}>{formatNumber(percent, 1, true)}%</Text>
          </>}
        </HStack>
        <Text>Total: <b>{formatNumber(sumDeliveredBarries * 1.6, 2, true)}</b> metros</Text>
      </Box>

      <TableContainer>
        <Table>
          <Thead>
            {!isMobile() &&
              <Tr>
                <Th>Data</Th>
                <Th isNumeric>Qtd.</Th>
                <Th isNumeric>Metros</Th>
                <Th isNumeric>Nota Fiscal</Th>
                <Th>Transporte</Th>
                <Th>Endereço</Th>
              </Tr>}
          </Thead>
          <Tbody>
            {filtered.map((ins, i) => {
              const address = project.addresses?.filter(a => a.id == ins.address)[0];
              const data = JSON.parse(ins.data) as InstallTransport[];
              const total = data.map(d => d.quantity).reduce((sum, e) => sum + e, 0);
              const meters = formatNumber(barriersToMeters(total), 2, false);
              const date = formatDate(ins.transported_at);
              const notas = data.map(d => d.nfe).join(', ');
              const transportes = transportToDisplay(data.map(d => d.type).join(', '));
              const addr = address ? <>{address?.logradouro}, {address?.numero}</> : 'Não especificado';
              const click = () => {
                if (canEdit) {
                  setModalMode({ type: 'edit', install: ins });
                  onOpen();
                }
              };

              if (isMobile()) {
                let bg = i % 2 != 0 ? 'gray.100' : undefined;

                return (
                  <>
                    <Tr key={i + 1} backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th width={'100px'}>Data</Th>
                      <Td>{date}</Td>
                    </Tr>
                    <Tr key={i + 1} backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th width={'100px'}>Qtd.</Th>
                      <Td>{total} ({meters} metros)</Td>
                    </Tr>
                    <Tr key={i + 3} backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Nota Fiscal</Th>
                      <Td width={'100%'}>{notas}</Td>
                    </Tr>
                    <Tr key={i + 4} backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th width={'100px'}>Transporte</Th>
                      <Td>{transportes}</Td>
                    </Tr>
                    <Tr key={i + 5} backgroundColor={bg}
                      cursor={'pointer'} className={i === (filtered.length - 1) ? '' : 'tr-separator'} onClick={click}
                    >
                      <Th width={'100px'}>Endereço</Th>
                      <Td>{addr}</Td>
                    </Tr>
                  </>
                )
              }

              return (
                <Tr key={i}
                  cursor={canEdit ? 'pointer' : undefined}
                  _hover={canEdit ? { backgroundColor: 'gray.200' } : {}}
                  style={{ transition: '0.2s' }}
                  onClick={click}
                >
                  <Td>{date}</Td>
                  <Td isNumeric>{total}</Td>
                  <Td isNumeric>{meters}</Td>
                  <Td isNumeric>{notas}</Td>
                  <Td>{transportes}</Td>
                  <Td>{addr}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
