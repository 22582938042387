import { Text, useDisclosure, Box, HStack, Button, Stack, Select, Progress, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { DatePicker } from '../DatePicker';
import { api } from '../../lib/api';
import { useAuth } from '../../lib/hooks/useAuth';
import { useIsMobile } from '../../lib/mobileHook';
import { BarrierProduction } from '../../models/BarrierProduction';
import { barriersToMeters, barriersUsedConcrete, metersToBarriers, Project } from '../../models/Project';
import { ProductionModal, ProductionModalMode } from '../modals/ProductionModal';
import { formatNumber, formatDate } from '../../lib/util';
import { FiAlertTriangle } from 'react-icons/fi';

export function ProductionTab({ project }: { project: Project; }) {
  const [startDate, setStartDate] = useState(project.start_date);
  const [endDate, setEndDate] = useState(project.end_date);
  const [sortBy, setSortBy] = useState('recent');

  const { user } = useAuth();

  const [productions, setProductions] = useState<BarrierProduction[]>([]);
  const [filtered, setFiltered] = useState<BarrierProduction[]>([]);
  const [sumMadeBarriers, setSumMadeBarriers] = useState(0);
  const [percent, setPercent] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  async function fetchProductions() {
    try {
      const response = await api.get<BarrierProduction[]>(`/project/${project.id}/production/`);
      setProductions(response.data.map(p => {
        return {
          ...p,
          concreted_at: new Date(p.concreted_at),
        };
      }));
    } catch (error) {
      console.error('Failed to fetch productions', error);
    }
  }

  useEffect(() => {
    fetchProductions();
  }, []);

  useEffect(() => {
    setFiltered(productions
      .filter(p => {
        if (new Date(p.concreted_at) >= endDate || new Date(p.concreted_at) <= startDate) return false;

        return true;
      })
      .sort((a, b) =>
        sortBy === 'older'
          ? new Date(a.concreted_at).getTime() - new Date(b.concreted_at).getTime()
          : new Date(b.concreted_at).getTime() - new Date(a.concreted_at).getTime())
    );
  }, [productions, sortBy, startDate, endDate]);

  useEffect(() => {
    setSumMadeBarriers(filtered.reduce((sum, e) => sum + e.quantity, 0));
  }, [filtered]);

  useEffect(() => {
    setPercent((sumMadeBarriers / metersToBarriers(project.required_barriers)) * 100);
  }, [sumMadeBarriers, project]);

  const { isMobile } = useIsMobile();

  const [canEdit, setCanEdit] = useState(true);
  useEffect(() => {
    setCanEdit(!project.completed);
  }, [project]);

  const [modalMode, setModalMode] = useState<ProductionModalMode>({
    production: null, type: 'create'
  });

  return (
    <Box>
      <ProductionModal isOpen={isOpen} onClose={onClose}
        mode={modalMode}
        onAddProduction={(p) => setProductions([...productions, p])}
        onDeleteProduction={(p) => setProductions(productions.filter(i => i.id !== p.id))}
        onEditProduction={(p) => setProductions(productions.map(i => i.id === p.id ? p : i))}
        project={project} />

      <HStack alignItems={'center'}>
        <Text fontSize={isMobile() ? 'xl' : '2xl'} fontWeight={900} mb={-1}>Produção</Text>
        {canEdit
          ? <Button colorScheme='blue' ml={'auto'} onClick={() => {
            setModalMode({ production: null, type: 'create' });
            onOpen();
          }}>Adicionar</Button>
          : <></>}
      </HStack>
      <Stack marginY={5} direction={isMobile() ? 'column' : 'row'}>
        <HStack>
          <DatePicker value={startDate} onChange={(d) => setStartDate(d ?? new Date())} />
          <DatePicker value={endDate} onChange={(d) => {
            d ??= new Date();
            d!.setHours(23, 59, 59);
            setEndDate(d);
          }} />
        </HStack>
        <Select variant={'filled'} ml={isMobile() ? 0 : 'auto'} maxWidth={'225px'} value={sortBy} onChange={(e) => setSortBy(e.target.value!)}>
          <option value={'recent'}>Mais recentes</option>
          <option value={'older'}>Mais antigos</option>
        </Select>
      </Stack>

      <Box background={'gray.100'} p={2} borderRadius={5} marginBottom={5}>
        <Text fontSize={'xl'}>
          <span style={{ fontWeight: 'bold' }}>Estatísticas </span> {isMobile() && <br />}
          ({formatDate(startDate, false)} - {formatDate(endDate, false)})
        </Text>
        <HStack spacing={0}>
          <Text pt={'3px'} mr={3}>
            <b>{sumMadeBarriers}</b> barreiras feitas de <b>{formatNumber(metersToBarriers(project.required_barriers), 2, true)}</b> totais
          </Text>
          {isMobile() && <span style={{ marginLeft: 'auto' }}>({formatNumber(percent, 1, true)}%)</span>}
          {!isMobile() &&
            <>
              <Progress borderRadius={5} flex={!isMobile() ? 1 : undefined} height={isMobile() ? '12px' : undefined} value={percent} backgroundColor={'gray.300'}
                colorScheme={percent >= 100 ? 'green' : 'blue'}
              />
              <Text pt={'3px'} ml={3}>{formatNumber(percent, 1, true)}%</Text>
            </>}
        </HStack>
        {user && user.user_kind === 'admin' && project.price_meter_barrier &&
          <Text>
            <b>Valor total:</b> R$ {formatNumber(project.price_meter_barrier * barriersToMeters(sumMadeBarriers), 2)}
          </Text>}
      </Box>

      <TableContainer>
        <Table>
          <Thead>
            {!isMobile() &&
              <Tr>
                <Th>Data</Th>
                <Th isNumeric>Qtd. barreiras</Th>
                <Th isNumeric>Concreto</Th>
                <Th>Notas</Th>
                {user && user.user_kind === 'admin' && <Th isNumeric>Preço Concreto</Th>}
                <Th></Th>
              </Tr>}
          </Thead>
          <Tbody>
            {filtered.map((p, i) => {
              const click = () => {
                if (canEdit) {
                  setModalMode({ production: p, type: 'edit' });
                  onOpen();
                }
              };

              const date = formatDate(new Date(p.concreted_at));
              const cqtd = formatNumber(barriersUsedConcrete(p.quantity), 2);
              const price = formatNumber(project.price_concrete * barriersUsedConcrete(p.quantity), 2);

              if (isMobile()) {
                let bg = i % 2 != 0 ? 'gray.100' : undefined;
                bg = !p.is_correct ? '#f29999' : bg;

                return (
                  <>
                    <Tr key={i + 0}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Data</Th>
                      <Td>{date}</Td>
                    </Tr>
                    <Tr key={i + 1}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Qtd. barreiras</Th>
                      <Td>{p.quantity}</Td>
                    </Tr>
                    <Tr key={i + 2}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Concreto</Th>
                      <Td>{cqtd} m³</Td>
                    </Tr>
                    {user && user.user_kind === 'admin' &&
                      <Tr key={i + 3}
                        backgroundColor={bg}
                        cursor={'pointer'} className='tr-list' onClick={click}
                      >
                        <Th>Preço Concreto</Th>
                        <Td>R$ {price}</Td>
                      </Tr>}
                    {p.notes &&
                      <Tr key={i + 4}
                        backgroundColor={bg}
                        cursor={'pointer'} onClick={click}
                        className={i === (filtered.length - 1) ? '' : 'tr-separator'}
                      >
                        <Th>Notas</Th>
                        <Td>{p.notes}</Td>
                      </Tr>}
                  </>
                );
              }

              return (

                <Tr key={i}
                  cursor={canEdit ? 'pointer' : undefined}
                  _hover={canEdit ? { backgroundColor: 'gray.200' } : {}}
                  style={{ transition: '0.2s' }}
                  css={!p.is_correct ? { backgroundColor: '#f29999 !important' } : {}}
                  onClick={click}
                >
                  <Td>{date}</Td>
                  <Td isNumeric>{p.quantity}</Td>
                  <Td isNumeric>{cqtd} m³</Td>
                  <Td maxWidth={'200px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>{p.notes}</Td>
                  {user && user.user_kind === 'admin' &&
                    <Td isNumeric>
                      R$ {price}
                    </Td>}
                  <Td>{!p.is_correct ? <FiAlertTriangle /> : <></>}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
