import { useState, useEffect } from 'react';

export function useIsMobile() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowSizeChange = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    // const isMobile = () => width <= 768;
    const isMobile = () => width <= 960;

    return { isMobile, width };
}