import { Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, FormControl, FormLabel, Input, HStack, Box, Button, Alert, AlertIcon, AlertDescription, ModalFooter, Checkbox, Stack, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Tooltip } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { DatePicker } from '../components/DatePicker';
import { Address } from '../models/Address';
import { Project } from '../models/Project';
import { AddressPicker } from '../components/AddressPicker';
import { CustomNumberInput } from '../components/CustomNumberInput';
import { useIsMobile } from '../lib/mobileHook';
import { createProject, editProject } from '../lib/repositories/projectRepository';
import { helperValidator } from '../lib/util';

export interface ProjectModalMode { project: Project | null, type: 'create' | 'edit'; }

export function ProjectModal(
  props: {
    isOpen: boolean;
    onClose: () => void;
    mode: ProjectModalMode;
    onProjectAdd: (newProject: Project) => void;
    onProjectDelete: (project: Project) => void;
    onProjectEdit: (project: Project) => void;
  }
) {
  const now = new Date();

  const [name, setName] = useState('');
  const [contractCode, setContractCode] = useState('');
  const [clientName, setClientName] = useState<string>('');
  const [requiredBarriers, setRequiredBarriers] = useState(1);
  const [startDate, setStartDate] = useState(now);
  const [endDate, setEndDate] = useState(now);
  const [priceConcrete, setPriceConcrete] = useState(0);
  const [priceSteel, setPriceSteel] = useState(0);
  const [priceInstallKit, setPriceInstallKit] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [priceMeterBarrier, setPriceMeterBarrier] = useState(0);
  const [priceInstall, setPriceInstall] = useState(0);

  const [addresses, setAddresses] = useState<Address[]>([]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Usado apenas no modo de edição
  const [modalDirty, setModalDirty] = useState(false);
  const [activeAddresses, setActiveAddreses] = useState<number[]>([]);
  const [newAddresses, setNewAddresses] = useState<Address[]>([]);

  const { isMobile } = useIsMobile();

  useEffect(() => {
    setError('');
    if (props.mode.type === 'create') {
      const now = new Date();

      setName('');
      setRequiredBarriers(1);
      setStartDate(now);
      setEndDate(now);
      setPriceConcrete(0);
      setPriceSteel(0);
      setPriceInstallKit(0);
      setAddresses([]);
      setNewAddresses([]);
      setTotalPrice(0);
      setContractCode('');

      setClientName('');

      setPriceMeterBarrier(0);
      setPriceInstall(0);
      setActiveAddreses([]);
    } else {
      const p = props.mode.project!;
      setName(p.name);
      setRequiredBarriers(p.required_barriers);
      setStartDate(p.start_date);
      setEndDate(p.end_date);
      setPriceConcrete(p.price_concrete);
      setPriceSteel(p.price_steel);
      setPriceInstallKit(p.price_install_kit);
      setAddresses(p.addresses!);
      setTotalPrice(p.total_price);
      setClientName(p.client_name);
      setCompleted(p.completed);
      setContractCode(p.code);
      setNewAddresses([]);
      setActiveAddreses(p.addresses!.filter(a => a.enabled).map(a => a.id!));
      setModalDirty(false);
      setPriceMeterBarrier(p.price_meter_barrier);
      setPriceInstall(p.price_install ?? 0);
    }
  }, [props.mode]);

  useEffect(() => {
    if (props.mode.type !== 'edit') return;
    const p = props.mode.project!;

    const dirty =
      (name != p.name)
      || (startDate != p.start_date)
      || (endDate != p.end_date)
      || (requiredBarriers != p.required_barriers)
      || (priceConcrete != p.price_concrete)
      || (priceSteel != p.price_steel)
      || (priceInstallKit != p.price_install_kit)
      || (completed != p.completed)
      || (totalPrice != p.total_price)
      || (priceMeterBarrier != p.price_meter_barrier)
      || (priceInstall != p.price_install)
      || (contractCode != p.code)
      || (newAddresses.length > 0);

    setModalDirty(dirty);
  }, [
    props.mode, name, startDate,
    endDate, requiredBarriers, priceConcrete,
    priceSteel, priceInstallKit, completed,
    totalPrice, priceMeterBarrier, priceInstall,
    newAddresses
  ]);

  async function handleConfirmButton() {
    setError('');
    const [ok, error] = helperValidator([
      { name: 'Identificador', value: name },
      { name: 'Código do Contrato', value: contractCode },
      { name: 'Nome do Cliente', value: clientName },
      { name: 'Data de início', value: startDate },
      { name: 'Data de término', value: endDate },
      { name: 'Total barreiras', value: requiredBarriers },
      { name: 'Preço barreira por metro', value: priceMeterBarrier },
      { name: 'Preço total do contrato', value: totalPrice },
      { name: 'Preço Concreto', value: priceConcrete, validator: priceValidator },
      { name: 'Preço Aço', value: priceSteel, validator: priceValidator },
      { name: 'Preço Kit de Instalação', value: priceInstallKit, validator: priceValidator },
      { name: 'Endereços', value: addresses, validator: addressesValidator },
    ]);

    if (!ok) return setError(error!);

    if (endDate < startDate) {
      return setError('Data de ínicio posterior a data de término');
    }

    setLoading(true);
    if (props.mode.type == 'create') {
      try {
        const [project, error] = await createProject({
          contractCode, name, clientName, startDate, endDate,
          requiredBarriers, priceConcrete, priceSteel,
          priceInstallKit, totalPrice, priceMeterBarrier,
          priceInstall
        }, addresses);

        if (error != null) return setError(error);

        props.onProjectAdd(project!);
        props.onClose();
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const [project, error] = await editProject(props.mode.project!, {
          contractCode,
          name,
          clientName,
          startDate,
          endDate,
          completed,
          requiredBarriers,
          priceConcrete,
          priceSteel,
          priceInstallKit,
          totalPrice,
          priceMeterBarrier,
          priceInstall,
        }, activeAddresses, newAddresses);

        if (error != null) return setError(error);

        props.onProjectEdit(project!);
        props.onClose();
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent mt={1}>
        <ModalCloseButton />
        <ModalHeader mb={-3}>{props.mode.type == 'create' ? 'Novo' : 'Editar'} Projeto</ModalHeader>
        <ModalBody>
          <Stack direction={isMobile() ? 'column' : 'row'}>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Nome identificador</FormLabel>
              <Input type='text' placeholder='Nome identificador' autoComplete='off'
                value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Código do Contrato</FormLabel>
              <Input type='text' placeholder='Código do Contrato' autoComplete='off'
                value={contractCode} onChange={(e) => setContractCode(e.target.value)} />
            </FormControl>
          </Stack>

          <FormControl mb={4} isRequired>
            <FormLabel marginBottom={0}>Nome Cliente</FormLabel>
            <Input type='text' placeholder='Nome cliente' autoComplete='off'
              value={clientName} onChange={(e) => setClientName(e.target.value)} />
          </FormControl>

          <HStack>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Data de início</FormLabel>
              <DatePicker value={startDate} onChange={(d) => setStartDate(d!)} />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Data de término</FormLabel>
              <DatePicker value={endDate} onChange={(d) => setEndDate(d!)} />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Total de barreiras</FormLabel>
              <CustomNumberInput
                type={'with-unit'} unit={'metros'} value={requiredBarriers} onChange={setRequiredBarriers}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Preço barreira por metro</FormLabel>
              <CustomNumberInput type={'money'} value={priceMeterBarrier} onChange={setPriceMeterBarrier} />
            </FormControl>
          </HStack>

          <FormControl mb={4} isRequired>
            <FormLabel marginBottom={0}>Preço Total do Contrato</FormLabel>
            <CustomNumberInput type={'money'} value={totalPrice} onChange={setTotalPrice} />
          </FormControl>

          <Box width={'100%'} backgroundColor={'gray.300'} height={'1px'} mb={3}></Box>

          <HStack>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Preço Aço/Armação</FormLabel>
              <CustomNumberInput type={'money'} value={priceSteel} onChange={setPriceSteel} />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Preço Kit de Instalação</FormLabel>
              <CustomNumberInput type={'money'} value={priceInstallKit} onChange={setPriceInstallKit} />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl mb={4} isRequired>
              <FormLabel marginBottom={0}>Preço Concreto/m³</FormLabel>
              <CustomNumberInput type={'money'} value={priceConcrete} onChange={setPriceConcrete} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel marginBottom={0}>Preço da Entrega (opcional)</FormLabel>
              <CustomNumberInput type={'money'} value={priceInstall} onChange={setPriceInstall} />
            </FormControl>
          </HStack>

          {props.mode.type === 'create' &&
            <>
              <FormControl>
                <FormLabel marginBottom={-1}>Endereços</FormLabel>
                <AddressPicker onAddAddress={(newAddress) => {
                  setError('');
                  const newAddresses = [...addresses, newAddress];
                  setAddresses(newAddresses.filter(
                    (obj, index, self) => index === self.findIndex(o => JSON.stringify(o) === JSON.stringify(obj))
                  ));
                }} />
              </FormControl>

              <Box maxH={'200px'} overflowY={'auto'}>
                {addresses.map((a, i) => (
                  <Box key={i} p={2} bg={'gray.50'} borderRadius={5} mb={3}>
                    <HStack>
                      <Box width={'100%'}>
                        <Text marginRight={'auto'}>{a.logradouro} {a.numero}</Text>
                        <Text marginRight={'auto'}>{a.cidade} - {a.uf}</Text>
                      </Box>
                      <Button colorScheme='red' variant={'ghost'}
                        onClick={() => {
                          const as = addresses.splice(i + 1, 1);
                          setAddresses(as);
                        }}
                      >
                        <FiX size={20} />
                      </Button>
                    </HStack>
                  </Box>
                ))}
              </Box>
            </>}

          {props.mode.type === 'edit' &&
            <FormControl isRequired mb={3}>
              <Checkbox isChecked={completed}
                onChange={(e) => setCompleted(e.target.checked)}
              >
                <Text mb={-0.5} fontWeight={'semibold'}>
                  Projeto finalizado?
                </Text>
              </Checkbox>
            </FormControl>}

          {props.mode.type === 'edit' &&
            <>
              <Text>Endereços</Text>
              <TableContainer>
                <Table layout={'fixed'} width={'100%'}>
                  <Thead>
                    <Tr>
                      <Th width={'80%'}>Endereço</Th>
                      <Th width={'20%'}>Ativo</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {addresses.map((a, i) => {
                      const asTxt =
                        `${a.logradouro}, ${a.numero}, ${a.cidade} - ${a.uf}`
                          .replace('Avenida', 'Av.')
                          .replace('Rua', 'R.');
                      return (
                        <Tr key={i}>
                          <Td width={'80%'}>
                            <Tooltip label={asTxt}>
                              <Text marginRight={'auto'} isTruncated>{asTxt}</Text>
                            </Tooltip>
                          </Td>
                          <Td width={'20%'}>
                            <Checkbox isChecked={activeAddresses.includes(a.id!)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setActiveAddreses(prev => {
                                  setModalDirty(true);
                                  if (isChecked) {
                                    return [...prev, a.id!];
                                  } else {
                                    return activeAddresses.filter(aa => aa !== a.id);
                                  }
                                });
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Box px={6}>
                <AddressPicker onAddAddress={(newAddress) => {
                  setError('');
                  const nue = [...newAddresses, newAddress];
                  setNewAddresses(nue.filter(
                    (obj, index, self) => index === self.findIndex(o => JSON.stringify(o) === JSON.stringify(obj))
                  ));
                }} />
              </Box>
              <Box maxH={'200px'} overflowY={'auto'}>
                {newAddresses.map((a, i) => (
                  <Box key={i} p={2} bg={'gray.50'} borderRadius={5} mb={3}>
                    <HStack>
                      <Box width={'100%'}>
                        <Text marginRight={'auto'}>{a.logradouro} {a.numero}</Text>
                        <Text marginRight={'auto'}>{a.cidade} - {a.uf}</Text>
                      </Box>
                      <Button colorScheme='red' variant={'ghost'}
                        onClick={() => {
                          const as = newAddresses.splice(i + 1, 1);
                          setNewAddresses(as);
                        }}
                      >
                        <FiX size={20} />
                      </Button>
                    </HStack>
                  </Box>
                ))}
              </Box>
            </>}
          {error &&
            <Alert status='error' borderRadius={5}>
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading} colorScheme='blue' onClick={handleConfirmButton} disabled={props.mode.type === 'edit' && !modalDirty}
          >
            {props.mode.type === 'edit' ? 'Editar' : 'Criar'} projeto
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const priceValidator = (value: number): [boolean, string | null] => {
  if (value <= 0) {
    return [false, 'Preço tem que ser maior que zero'];
  }

  return [true, null];
};

const addressesValidator = (values: Address[]): [boolean, string | null] => {
  if (values.length <= 0) {
    return [false, 'A menos um endereço é necessário para um projeto'];
  }
  return [true, null];
};