import { Select, Flex, Icon, HStack, Box, Text, Input, Th, Td, InputGroup, InputLeftElement, TableContainer, Table, Thead, Tr, Tbody, Menu, MenuButton, MenuList, MenuItem, IconButton, Button, useDisclosure, Spinner } from '@chakra-ui/react';
import { FiChevronDown, FiPlus, FiSearch } from 'react-icons/fi';
import { useEffect, useState } from 'react';

import { User } from '../models/User';
import { useAuth } from '../lib/hooks/useAuth';
import { getHighlightedText, userKindToDisplay } from '../lib/util';
import { useIsMobile } from '../lib/mobileHook';
import { WorkerModal, WorkerModalProps } from '../components/modals/WorkerModal';
import { fetchWorkers } from '../lib/repositories/usersRepository';

function WorkerOptions(props: { onClick: () => void; }) {
  return (
    <>
      <Menu>
        <MenuButton as={IconButton} icon={<FiChevronDown />} backgroundColor={'gray.300'} />
        <MenuList>
          <MenuItem onClick={props.onClick}>Visualizar</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

export function WorkersPage() {
  const [filter, setFilter] = useState('');
  const [kindFilter, setKindFilter] = useState('');
  const [usersData, setUsersData] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useAuth();
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setLoading(true);
    fetchWorkers()
      .then(r => setUsersData(r ?? []))
      .finally(() => setLoading(false));
  }, []);

  function openModal(props: WorkerModalProps) {
    setModalMode(props);
    onOpen();
  }

  const [modalMode, setModalMode] = useState<WorkerModalProps>(
    { user: null, type: 'create' }
  );

  useEffect(() => {
    setFilteredUsers(usersData
      .filter((w) => {
        if (kindFilter) {
          if (w.user_kind != kindFilter) { return false; }
        }

        if (!filter) return true;
        const data = `${w.full_name} ${w.email}`;
        return data.toLowerCase().includes(filter.toLowerCase());
      })
    );
  }, [usersData, filter, kindFilter]);

  return (
    <>
      <WorkerModal isOpen={isOpen} onClose={onClose} mode={modalMode}
        onUserAdd={(user: User) => {
          setUsersData([...usersData, user]);
        }}
        onUserDelete={(user: User) => {
          setUsersData(usersData.filter(i => i.id !== user.id));
        }}
        onUserEdit={(user: User) => {
          if (user.id == user!.id) {
            setUser(user);
          }
          setUsersData(usersData.map(u => u.id === user.id ? user : u));
        }} />

      <Box
        p={3}
        bg='gray.50'
        flex={1}
        borderBottomRightRadius={10}
      >
        <Text fontSize='3xl' fontWeight={900}>
          Funcionários
        </Text>

        {loading ? <Flex width={'100%'} justifyContent={'center'}><Spinner /></Flex> :
          <>
            <Flex direction={isMobile() ? 'column' : 'row'} >
              <InputGroup width={isMobile() ? undefined : '200%'} mr={4} mb={isMobile() ? 2 : 0}>
                <InputLeftElement pointerEvents={'none'}>
                  <Icon as={FiSearch}></Icon>
                </InputLeftElement>
                <Input placeholder={`Pesquisar${!isMobile() ? ' funcionários' : ''}`}
                  onChange={(e) => { setFilter(e.target.value); }} />
              </InputGroup>
              <InputGroup>
                <Select variant={'filled'} placeholder='Todos os tipos'
                  onChange={(e) => setKindFilter(e.target.value)}
                >
                  <option value='worker'>Operário</option>
                  <option value='office'>Escritório</option>
                  <option value='admin'>Administrador</option>
                </Select>
              </InputGroup>
            </Flex>

            <Box height={'450px'} overflowY={'scroll'}>
              {filteredUsers.length == 0 ?
                <Flex width={'100%'} justifyContent={'center'} marginY={5}>
                  <Text fontWeight={'bold'} fontSize={'20px'}>Nenhum usuário correspondente ao filtro</Text>
                </Flex> : <></>}
              {usersData.length > 0 &&
                <TableContainer>
                  <Table variant={isMobile() ? 'simple' : 'striped'} colorScheme='gray'>
                    <Thead position={'sticky'} top={0} zIndex={1}>
                      {filteredUsers.length > 0 && !isMobile() &&
                        <Tr>
                          <Th>Nome</Th>
                          <Th>Tipo</Th>
                          <Th>E-Mail</Th>
                        </Tr>}
                    </Thead>
                    <Tbody>
                      {usersData && filteredUsers
                        .map((w, i) => {
                          const filterRegExp = new RegExp(`(${filter})`, 'gi');

                          const name = filter ? getHighlightedText(w.full_name, filter, filterRegExp) : w.full_name;
                          const kind = userKindToDisplay(w.user_kind);
                          const mail = filter ? getHighlightedText(w.email, filter, filterRegExp) : w.email;

                          const click = () =>
                            openModal({ user: usersData.filter(u => u.id == w.id)[0], type: 'edit' });

                          if (!isMobile())
                            return (
                              <Tr key={i}>
                                <Td>
                                  <Text maxWidth={'200px'}
                                    whiteSpace={'nowrap'}
                                    overflow={'hidden'}
                                    textOverflow={'ellipsis'}
                                  >{name}</Text>
                                </Td>
                                <Td>{kind}</Td>
                                <Td>{mail}</Td>
                                <Td isNumeric><WorkerOptions onClick={click} /></Td>
                              </Tr>
                            );
                          else
                            return (
                              <>
                                <Tr key={i}
                                  backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
                                  cursor={'pointer'} className='tr-list' onClick={click}
                                >
                                  <Th width={'100px'}>Nome</Th>
                                  <Td>{name}</Td>
                                </Tr>
                                <Tr key={i + 1}
                                  backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
                                  cursor={'pointer'} className='tr-list' onClick={click}>
                                  <Th>Tipo</Th>
                                  <Td>{kind}</Td>
                                </Tr>
                                <Tr key={i + 2}
                                  backgroundColor={i % 2 != 0 ? 'gray.100' : undefined}
                                  cursor={'pointer'} className={i === (filteredUsers.length - 1) ? '' : 'tr-separator'} onClick={click}
                                >
                                  <Th>E-Mail</Th>
                                  <Td>{mail}</Td>
                                </Tr>
                              </>
                            );
                        })}
                    </Tbody>
                  </Table>
                </TableContainer>}
            </Box>

            {user?.user_kind == 'admin' &&
              <HStack mt={4} justifyContent={'end'}>
                <Button
                  leftIcon={<FiPlus />}
                  colorScheme='blue' onClick={() => openModal({ user: null, type: 'create' })}>Novo funcionário</Button>
              </HStack>}
          </>
        }
      </Box>
    </>
  );
}
