import { CONCRETE_PER_BARRIER, METER_PER_BARRIER } from '../lib/constansts';
import { Address } from './Address';

export interface Project {
  id: number,
  code: string,
  name: string,
  client_name: string,
  start_date: Date,
  end_date: Date,
  completed: boolean,
  required_barriers: number,
  price_concrete: number,
  price_steel: number,
  price_install_kit: number,
  total_price: number,
  price_meter_barrier: number,
  price_install: number|null,
  
  addresses?: Address[],
}

export function metersToBarriers(size: number): number {
  return size / METER_PER_BARRIER;
}

export function barriersToMeters(barriers: number): number {
  return barriers * METER_PER_BARRIER;
}

export function barriersUsedConcrete(barriers: number): number {
  return barriers * CONCRETE_PER_BARRIER;
}
