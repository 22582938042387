import { Box, Image, Spacer, Text, HStack, Link, Divider, Center } from '@chakra-ui/react';
import imgUrl from '../assets/logo.png';
import { useAuth } from '../lib/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { useIsMobile } from '../lib/mobileHook';

export function Header(props: { onMenuClick: () => void; }) {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { isMobile } = useIsMobile();

  const headerLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      <Box>
        <HStack
          bg='#131c4c' color='white'
          py={5}
          alignItems={'center'}
          spacing={0}
        >
          <Box mx={2}>
            {isMobile() && <Box onClick={props.onMenuClick}><FiMenu size={'25'}></FiMenu></Box>}
          </Box>
          {/* a perninha do g na logo tem uma altura de 7px :0 */}
          <Image
            alt='Logo Segurvia' src={imgUrl}
            onClick={() => !isMobile() ? navigate('/') : {}}
            cursor={'pointer'}
            height={'35px'} mb={'-7px'}></Image>
          <Spacer></Spacer>
          {!isMobile() && <Text>Olá, {user ? user.full_name.split(' ')[0] : '?'}</Text>}
          {!isMobile() && <Center height={'20px'} mx={2}><Divider orientation='vertical' /></Center>}
          <Link onClick={() => headerLogout()} textDecoration={'underline'} mr={isMobile() ? 2 : 4}>Sair</Link>
        </HStack>
      </Box>
    </>
  );
}