import { Text, useDisclosure, Box, HStack, Button, Stack, Select, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { api } from '../../lib/api';
import { useIsMobile } from '../../lib/mobileHook';
import { MaterialData } from '../../models/MaterialData';
import { Project } from '../../models/Project';
import { formatDate, formatNumber, materialUnit, materialToDisplay } from '../../lib/util';
import { DatePicker } from '../DatePicker';
import { MaterialModal, MaterialModalMode } from '../modals/MaterialsModal';


export function MaterialsTab({ project }: { project: Project; }) {
  const [startDate, setStartDate] = useState(project.start_date);
  const [endDate, setEndDate] = useState(project.end_date);

  const [sortBy, setSortBy] = useState('recent');
  const [filterMaterial, setFilterMaterial] = useState('all');

  const [materials, setMaterials] = useState<MaterialData[]>([]);
  const [filtered, setFiltered] = useState<MaterialData[]>([]);

  async function fetchMaterials() {
    try {
      const response = await api.get<MaterialData[]>(`/project/${project.id}/rawmaterials/`);
      setMaterials(response.data.map(e => {
        return {
          ...e,
          delivered_at: new Date(e.delivered_at)
        };
      }));
    } catch (error) {
      console.error('Failed to fetch materials', error);
    }
  }

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    setFiltered(materials
      .filter(m => {
        if (m.delivered_at >= endDate || m.delivered_at <= startDate) {
          return false;
        }

        if (filterMaterial !== 'all') {
          return m.subject == filterMaterial;
        }

        return true;
      }).sort((a, b) => sortBy === 'older'
        ? a.delivered_at.getTime() - b.delivered_at.getTime()
        : b.delivered_at.getTime() - a.delivered_at.getTime())
    );
  }, [materials, sortBy, startDate, endDate, filterMaterial]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [totalSteel, setTotalSteel] = useState(0);
  const [totalKit, setTotalKit] = useState(0);
  const [totalConcrete, setTotalConcrete] = useState(0);

  useEffect(() => {
    function getMax(key: string): number {
      return filtered.filter(m => m.subject == key)
        .reduce((sum, e) => sum + Number(e.total_amount), 0);
    }

    setTotalSteel(getMax('steel'));
    setTotalConcrete(getMax('concrete'));
    setTotalKit(getMax('connection_kit'));
  }, [filtered]);

  const { isMobile } = useIsMobile();

  const [canEdit, setCanEdit] = useState(true);
  useEffect(() => {
    setCanEdit(!project.completed);
  }, [project]);

  const [modalMode, setModalMode] = useState<MaterialModalMode>({
    type: 'create', material: null
  });

  return (
    <Box>
      <MaterialModal project={project} isOpen={isOpen} onClose={onClose}
        mode={modalMode}
        onAddMaterial={(m) => setMaterials([...materials, m])}
        onDeleteMaterial={(m) => setMaterials(materials.filter(i => i.id !== m.id))}
        onEditMaterial={(m) => setMaterials(materials.map(i => i.id === m.id ? m : i))}
      />

      <HStack alignItems={'center'}>
        <Text fontSize={isMobile() ? 'xl' : '2xl'} fontWeight={900} mb={-1}>Matéria Prima</Text>
        {canEdit
          ? <Button colorScheme='blue' ml={'auto'}
            onClick={() => {
              setModalMode({ type: 'create', material: null });
              onOpen();
            }}>Adicionar</Button>
          : <></>}
      </HStack>
      <Stack marginY={5} direction={isMobile() ? 'column' : 'row'}>
        <HStack>
          <DatePicker value={startDate} onChange={(d) => setStartDate(d ?? new Date())} />
          <DatePicker value={endDate} onChange={(d) => {
            d ??= new Date();
            d!.setHours(23, 59, 59);
            setEndDate(d);
          }} />
        </HStack>
        <Stack direction={'row'} width={isMobile() ? '100%' : '60%'}>
          <Select variant={'filled'} maxWidth={'225px'} value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
            <option value={'recent'}>Mais recentes</option>
            <option value={'older'}>Mais antigos</option>
          </Select>
          <Select variant={'filled'} maxWidth={'225px'} value={filterMaterial} onChange={(e) => setFilterMaterial(e.target.value)}>
            <option value={'all'}>Todos materiais</option>
            <option value={'steel'}>Aço</option>
            <option value={'concrete'}>Concreto</option>
            <option value={'connection_kit'}>Kit de ligação</option>
          </Select>
        </Stack>
      </Stack>

      <Box background={'gray.100'} p={2} borderRadius={5} marginBottom={5}>
        <Text fontSize={'xl'}>
          <span style={{ fontWeight: 'bold' }}>
            Estatísticas {filterMaterial !== 'all' ? `(${materialToDisplay(filterMaterial)}) ` : ''}
          </span>
          {isMobile() && <br />}
          ({formatDate(startDate, false)} - {formatDate(endDate, false)})
        </Text>
        {(filterMaterial === 'all' || filterMaterial === 'steel') && <Text>Total Aço:      {formatNumber(totalSteel)} armações</Text>}
        {(filterMaterial === 'all' || filterMaterial === 'concrete') && <Text>Total Concreto: {formatNumber(totalConcrete)} {materialUnit('concrete')}</Text>}
        {(filterMaterial === 'all' || filterMaterial === 'connection_kit') && <Text>Total Kits:     {formatNumber(totalKit, 0)} {materialUnit('connection_kit')}</Text>}
      </Box>

      <TableContainer>
        <Table>
          <Thead>
            {!isMobile() &&
              <Tr>
                <Th>Data</Th>
                <Th>Material</Th>
                <Th>Qtd.</Th>
                <Th>Notas</Th>
                <Th></Th>
              </Tr>}
          </Thead>
          <Tbody>
            {filtered.map((m, i) => {
              const unit = m.subject === 'steel' ? 'armações' : materialUnit(m.subject);
              const date = formatDate(m.delivered_at);
              const materialName = materialToDisplay(m.subject);
              const quantity = formatNumber(Number(m.total_amount), 2);
              const click = () => {
                if (canEdit) {
                  setModalMode({ type: 'edit', material: m });
                  onOpen();
                }
              };

              if (isMobile()) {
                let bg = i % 2 != 0 ? 'gray.100' : undefined;
                bg = !m.is_correct ? '#f29999' : bg;

                return (
                  <>
                    <Tr key={i}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Data</Th>
                      <Td>{date}</Td>
                    </Tr>
                    <Tr key={i + 1}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Material</Th>
                      <Td>{materialName}</Td>
                    </Tr>
                    <Tr key={i + 2}
                      backgroundColor={bg}
                      cursor={'pointer'} className='tr-list' onClick={click}
                    >
                      <Th>Qtd.</Th>
                      <Td>{quantity} {unit}</Td>
                    </Tr>
                    {m.notes && <Tr key={i + 3}
                      backgroundColor={bg}
                      cursor={'pointer'} className={i === (filtered.length - 1) ? '' : 'tr-separator'} onClick={click}
                      >
                      <Th>Notas</Th>
                      <Td>{m.notes}</Td>
                    </Tr>}
                  </>
                );
              }

              return (
                <Tr key={i} css={!m.is_correct ? { backgroundColor: '#f29999 !important' } : {}}
                  cursor={canEdit ? 'pointer' : undefined}
                  _hover={canEdit ? { backgroundColor: 'gray.200' } : {}}
                  style={{ transition: '0.2s' }}
                  onClick={click}
                >
                  {/* <Td>{m.id}</Td> */}
                  <Td>{date}</Td>
                  <Td>{materialName}</Td>
                  <Td>{quantity} {unit}</Td>
                  <Td maxWidth={'200px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>{m.notes}</Td>
                  <Td>{!m.is_correct ? <FiAlertTriangle /> : <></>}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
