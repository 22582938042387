import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, HStack, FormControl, FormLabel, Input, InputGroup, Alert, AlertIcon, AlertDescription, ModalFooter, Button, Textarea, Checkbox, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { DatePicker } from '../DatePicker';
import { api } from '../../lib/api';
import { BarrierProduction } from '../../models/BarrierProduction';
import { Project } from '../../models/Project';
import { timeFrom, timeNow } from '../../lib/util';

export interface ProductionModalMode {
  production: BarrierProduction | null,
  type: 'create' | 'edit',
}

export function ProductionModal(props: {
  project: Project,
  mode: ProductionModalMode,
  isOpen: boolean,
  onClose: () => void,
  onAddProduction: (p: BarrierProduction) => void,
  onDeleteProduction: (p: BarrierProduction) => void,
  onEditProduction: (p: BarrierProduction) => void,
}) {
  const [producedBarriers, setProducedBarriers] = useState(0);
  const [time, setTime] = useState(timeNow());
  const [date, setDate] = useState(new Date());

  const [notes, setNotes] = useState('');
  const [correct, setCorrect] = useState(true);

  const [error, setError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  async function handleConfirmButton() {
    setError('');

    if (!time || !date) {
      setError('Data e hora são obrigatórios');
      return;
    }

    if (producedBarriers < 0) {
      setError('Barreiras produzidas precisa ser um número positivo ou zero');
      return;
    }

    const [hours, minutes] = time.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);

    if (date > new Date()) {
      setError('Data de produção não pode ser no futuro');
      return;
    }

    if (date < props.project.start_date) {
      setError('Data de produção precisa ser posterior ao ínicio do projeto');
      return;
    }

    if (props.mode.type === 'create') {
      await createProduction();
    } else {
      await editProduction();
    }
  }

  async function deleteProduction() {
    try {
      await api.delete(`/project/${props.project.id}/production/${props.mode.production!.id}/`);
      props.onDeleteProduction(props.mode.production!);
      props.onClose();
    } catch (error) {
      console.error('Failed to delete production', error);
    }
  }

  async function editProduction() {
    try {
      setButtonLoading(true);
      const response = await api.patch(`/project/${props.project.id}/production/${props.mode.production!.id}/`, {
        concreted_at: date.toISOString(),
        quantity: producedBarriers,
        is_correct: correct,
        notes: notes
      });

      props.onEditProduction({
        ...response.data,
        concreted_at: new Date((response.data as BarrierProduction).concreted_at)
      });
      props.onClose();
    } catch (error) {
      console.error('Failed to edit production', error);
    } finally {
      setButtonLoading(false);
    }
  }

  async function createProduction() {
    try {
      setButtonLoading(true);
      const response = await api.post(`/project/${props.project.id}/production/`, {
        project: props.project.id,
        concreted_at: date.toISOString(),
        quantity: producedBarriers,
        is_correct: correct,
        notes: notes
      });
      props.onAddProduction({
        ...response.data,
        concreted_at: new Date(response.data.concreted_at),
      });
      props.onClose();
    } catch (error) {
      console.error('Failed to register production', error);
    } finally {
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    setError('');
    if (props.mode.type === 'create') {
      setProducedBarriers(0);
      setTime(timeNow());
      setDate(new Date());
      setCorrect(false);
      setNotes('');
    } else {
      const p = props.mode.production!;
      setProducedBarriers(p.quantity);
      setTime(timeFrom(p.concreted_at));
      setDate(p.concreted_at);
      setCorrect(p.is_correct);
      setNotes(p.notes)
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay></ModalOverlay>
      <ModalContent>
        <ModalHeader>{props.mode.type === 'create' ? 'Registrar' : 'Editar'} Produção</ModalHeader>
        <ModalBody>
          <HStack mb={3}>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Data</FormLabel>
              <DatePicker value={date} onChange={(d) => setDate(d!)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel marginBottom={0}>Hora</FormLabel>
              <Input type='time' value={time} onChange={(e) => setTime(e.target.value)} />
            </FormControl>
          </HStack>
          <FormControl isRequired mb={3}>
            <FormLabel marginBottom={0}>Barreiras produzidas</FormLabel>
            <InputGroup>
              <Input type='number' min={0.0001}
                value={String(producedBarriers)}
                autoFocus
                onChange={(e) => setProducedBarriers(Number(e.target.value))} />
            </InputGroup>
          </FormControl>
          <FormControl mb={3}>
            <FormLabel marginBottom={0}>Notas</FormLabel>
            <Textarea autoFocus value={notes} onChange={(e) => setNotes(e.target.value)}></Textarea>
          </FormControl>
          <FormControl isRequired mb={3}>
            <Checkbox isChecked={correct} onChange={(e) => setCorrect(e.target.checked)}>
              <Text mb={-0.5} fontWeight={'semibold'}>
                Produção Correta
              </Text>
            </Checkbox>
          </FormControl>
          {error &&
            <Alert status='error' borderRadius={5}>
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
        </ModalBody>
        <ModalFooter>
          {props.mode.type === 'edit' && <Button variant='ghost' colorScheme='red' mr={'auto'}
            onClick={async () => {
              const ok = confirm('Tem certeza que deseja deletar esse registro?');
              if (ok) {
                await deleteProduction();
              }
            }}
          >Excluir</Button>}
          <Button colorScheme='blue' onClick={handleConfirmButton} isLoading={buttonLoading}>
            {props.mode.type === 'create' ? 'Adicionar' : 'Salvar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}