import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../lib/api';
import { Project } from '../models/Project';
import { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, Text, Spinner, Icon, Spacer, HStack, Button, useDisclosure, Center } from '@chakra-ui/react';
import { FiArrowLeft, FiCalendar, FiCheckCircle, FiClock, FiMapPin, FiUser } from 'react-icons/fi';
import { ProjectModal } from './ProjectModal';
import { useAuth } from '../lib/hooks/useAuth';
import { ProductionTab } from '../components/tabs/ProductionTab';
import { MaterialsTab } from '../components/tabs/MaterialsTab';
import { InstallsTab } from '../components/tabs/InstallsTab';
import { formatDate } from '../lib/util';

export function ProjectView() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  async function fetchProject() {
    try {
      const response = await api.get<Project>(`/project/${id}/`);
      const project = {
        ...response.data,
        start_date: new Date(response.data.start_date),
        end_date: new Date(response.data.end_date),
      };

      setProject(project);
    } catch (error) {
      console.error('Failed to fetch projects', error);
      navigate('/projects');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const TAB_STORAGE_KEY = 'projectLastTab';
  const [tabIndex, setTabIndex] = useState(() => {
    const savedTab = localStorage.getItem(TAB_STORAGE_KEY) ?? '0';
    return parseInt(savedTab);
  });

  const onTabChange = (index: number) => {
    setTabIndex(index);
    localStorage.setItem(TAB_STORAGE_KEY, index.toString());
  };

  return (
    <>
      <Box
        p={3}
        bg='gray.50'
        flex={1}
        borderBottomRightRadius={10}
        minH={'600px'}
      >
        {loading ? <Flex width={'100%'} justifyContent={'center'}><Spinner /></Flex> :
          <>
            <ProjectModal
              isOpen={isOpen}
              onClose={onClose}
              mode={{ project: project!, type: 'edit' }}
              onProjectAdd={(_newProject: Project) => { }}
              onProjectDelete={(_project: Project) => { }}
              onProjectEdit={(project: Project) => {
                setProject(project);
              }}
            />
            <HStack mb={2}>
              <Center _hover={{ backgroundColor: 'gray.300' }} style={{ transition: 'background 0.2s' }} borderRadius={100}>
                <FiArrowLeft
                  cursor={'pointer'}
                  onClick={() => navigate('/projects/')}
                  size={20}
                />
              </Center>
              <Text fontSize='3xl' fontWeight={900} mb={-1}>
                {project!.name} ({project!.code})
              </Text>
              {user && user.user_kind == 'admin' &&
                <Button ml={'auto'} colorScheme='blue' onClick={onOpen}>
                  <Text mb={'-4px'}>Editar</Text>
                </Button>
              }
            </HStack>
            <Flex alignItems={'center'} mb={1}>
              <Icon as={FiUser} mr={1} mb={1}></Icon>
              <Text fontWeight={'medium'}>{project!.client_name}</Text>
            </Flex>
            <HStack spacing={0} mb={1}>
              <Icon as={FiMapPin} mr={1} mb={'auto'} mt={'3px'}></Icon>
              <Box>
                {project?.addresses?.filter(a => a.enabled).map((a, i) => (
                  <Text key={i}>{!a.cep && 'Rodovia'} {a.logradouro}, {a.numero}, {a.cidade} - {a.uf}</Text>
                ))}
              </Box>
            </HStack>
            <Flex alignItems={'center'} mb={1}>
              <Icon as={FiCalendar} mr={1} mb={1}></Icon>
              <Text fontWeight={'medium'}>{formatDate(project!.start_date, false)} - {formatDate(project!.end_date, false)}</Text>
            </Flex>
            <Flex alignItems={'center'}>
              <Icon as={project!.completed ? FiCheckCircle : FiClock} mr={1} mb={1}></Icon>
              <Text fontWeight={'medium'}>
                {project!.completed ? <b>Completo</b> : 'Em andamento'}
              </Text>
            </Flex>
            <Spacer mt={5} />
            <Tabs variant={'enclosed'} index={tabIndex} onChange={onTabChange}>
              <TabList>
                <Tab>Produção</Tab>
                <Tab>Matéria Prima</Tab>
                <Tab>Entregas</Tab>
              </TabList>

              <TabPanels className={'project-tab-panels'}>
                <TabPanel>
                  <ProductionTab project={project!} />
                </TabPanel>
                <TabPanel>
                  <MaterialsTab project={project!} />
                </TabPanel>
                <TabPanel>
                  <InstallsTab project={project!} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        }
      </Box>
    </>
  );
}
